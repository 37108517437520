<template>
  <div class="aboutus">
    <div class="box">
      <div class="title">
        <span>摹图AI概况</span>
      </div>
      <div class="content">
        <p>摹图AI 是辰夫（天津）信息技术有限公司开发的一款基于 AI 大模型的创新工具，聚焦于 AI 换装、AI 商拍以及 AI 虚拟模特生成领域。在电商和服装电商行业中，当前传统的换装、商拍以及虚拟模特制作成本颇高。而摹图能够凭借先进的 AI 技术，显著降低这些环节的成本。它能快速生成逼真的换装效果、高质量的商拍场景以及栩栩如生的虚拟模特，极大地提高了效率，节省了人力、物力和时间成本，为电商和服装电商带来更具性价比的解决方案。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  methods: {

  },
}
</script>

<style scoped>
@font-face {
  font-family: 'youshe';
  src: url('../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.aboutus {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background: #fff;
}
.box {
  width: 60vw;
  padding-top: 100px;
}
.title {
  font-family: 'youshe';
  font-size: 30px;
}
.content {
  text-align: left;
  line-height: 30px;
}
</style>