<template>
  <!-- <el-dialog :visible.sync="visible" width="850px" :before-close="handleClose" class="login-dialog">
    <div class="dialog-content">
      <div class="left-panel">
        <div class="content">
          <img src="@/assets/logo.png" alt="Logo" class="logo">
          <h2>一键AI换装</h2>
          <h3>快速生成高颜值AI模特</h3>
          <ul>
            <li>✔ 快速AI换装</li>
            <li>✔ 支持多类型模特选择</li>
            <li>✔ 支持多场景选择</li>
            <li>✔ 支持高清图片下载</li>
            <li>✔ 支持固定同一模特生成</li>
            <li>✔ 支持自定义描述</li>
          </ul>
        </div>
      </div>
      <div class="right-panel">
        <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="custom-tabs">
          <el-tab-pane label="短信登录" name="sms-login">
            <el-form :model="smsLoginForm" ref="smsLoginForm" class="login-form">
              <el-form-item>
                <el-input v-model="smsLoginForm.phone" placeholder="输入手机号" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="code-input">
                  <el-input v-model="smsLoginForm.code" placeholder="输入验证码" class="custom-input"></el-input>
                  <el-button
                    type="primary"  
                    :disabled="isCountdown"  
                    @click="sendSmsCode"  
                    class="custom-button"  
                  >  
                    {{ isCountdown ? `${countdown}秒后重新发送` : '获取验证码' }}  
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="smsLoginForm.agree" class="custom-checkbox">
                  登录/注册即代表我已同意
                  
                </el-checkbox>
                <a @click="showPrivacyPolicy" style="color: brown;">《用户协议和隐私政策》</a>
              
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="smsLogin" class="login-button custom-button">登录/注册</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="password-login">
            <el-form :model="passwordLoginForm" ref="passwordLoginForm" class="login-form">
              <el-form-item>
                <el-input v-model="passwordLoginForm.phone" placeholder="输入手机号/账号" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="passwordLoginForm.password" type="password" placeholder="输入密码" show-password class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="passwordLogin" class="login-button custom-button">登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <PrivacyPolicy :visible.sync="isPrivacyPolicyVisible" />
    </div>
  </el-dialog> -->
  <el-dialog :visible.sync="visible" width="850px" :show-close="false" :before-close="handleClose" class="login-dialog">
    <div class="dialog-content">
      <div class="left-panel">
        <div class="content">
          <img src="@/assets/logo.png" alt="Logo" class="logo">
          <h2>{{ $t('yijianshengtu') }}</h2>
          <h3>{{ $t('jikekeyong') }}</h3>
          <ul>
            <li>
              <img src="@/assets/correct.png"  alt="correct" class="currect" />
              <span>{{ $t('zhichizhenren') }}</span>
            </li>
            <li>
              <img src="@/assets/correct.png"  alt="correct" class="currect" />
              <span>{{ $t('zhichiyijian') }}</span>
            </li>
            <li>
              <img src="@/assets/correct.png"  alt="correct" class="currect" />
              <span>{{ $t('zhichigaoqing') }}</span>
            </li>
            <li>
              <img src="@/assets/correct.png"  alt="correct" class="currect" />
              <span>{{ $t('zhichidingzhi') }}</span>
            </li>
            <li>
              <img src="@/assets/correct.png"  alt="correct" class="currect" />
              <span>{{ $t('manzushangjia') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-panel">
        <div class="closediv">
          <i class="el-icon-close" style="font-size: 22px; cursor: pointer" @click="handleClose"></i>
        </div>
        <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="custom-tabs">
          <el-tab-pane :label="$t('duanxindenglu')" name="sms-login">
            <el-form :model="smsLoginForm" ref="smsLoginForm" class="login-form">
              <el-form-item>
                <el-input v-model="smsLoginForm.phone" :placeholder="$t('shurushoujihao')" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="code-input">
                  <el-input v-model="smsLoginForm.code" :placeholder="$t('shuruyanzhengma')" class="custom-input"></el-input>
                  <el-button
                    type="text"
                    :disabled="isCountdown"
                    @click="sendSmsCode"
                    class="loginbtn"
                  >
                    {{ isCountdown ? `${countdown} ` + $t('chongxinfasong') : $t('huoquyanzhengma') }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="smsLogin" class="login-button custom-button">{{ $t('denglu') }}</el-button>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <!-- <el-checkbox v-model="smsLoginForm.agree" class="custom-checkbox">
                登录/注册即代表我已同意
              </el-checkbox> -->
              <div>{{ $t('weizhuceshuoming') }}</div>
              <div style="margin-top: 5px">
                <span>{{ $t('dengluzhuceyitongyi') }}</span>
                <a @click="showPrivacyPolicy" style="color: #4765fd; cursor: pointer;">{{ $t('yinsizhengce') }}</a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('mimadenglu')" name="password-login">
            <el-form :model="passwordLoginForm" ref="passwordLoginForm" class="login-form">
              <el-form-item>
                <el-input v-model="passwordLoginForm.phone" :placeholder="$t('shuruzhanghao')" class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="passwordLoginForm.password" type="password" :placeholder="$t('shurumima')" show-password class="custom-input"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="passwordLogin" class="login-button custom-button">{{ $t('denglu') }}</el-button>
              </el-form-item>
            </el-form>
            <div style="text-align: center; margin-top: 20px">
              <span>{{ $t('dengluzhuceyitongyi') }}</span>
              <a @click="showPrivacyPolicy" style="color: #4765fd; cursor: pointer;">{{ $t('yinsizhengce') }}</a>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <PrivacyPolicy :visible.sync="isPrivacyPolicyVisible" />
    </div>
  </el-dialog>
</template>
  
<script>
import apiClient from '@/services/api'; // 假设你有一个 apiClient 处理请求
import PrivacyPolicy from './PrivacyPolicy.vue'
import { eventBus } from '@/main';

export default {
  name: 'LoginDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
  PrivacyPolicy
},
  data() {
    return {
      countdown: 60, // 倒计时秒数  
      isCountdown: false, // 是否正在进行倒计时  
      isPrivacyPolicyVisible: false,
      activeTab: 'sms-login',
      smsLoginForm: {
        phone: '',
        code: '',
        agree: false
      },
      passwordLoginForm: {
        phone: '',
        password: ''
      }
    };
  },
  methods: {
    showPrivacyPolicy() {
      this.isPrivacyPolicyVisible = true;
    },
    handleTabClick(tab) {
      this.activeTab = tab.name;
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
    async sendSmsCode() {
      try {  
        const response = await apiClient.get('/sendCode', {  
          params: {  
            phone: this.smsLoginForm.phone  
          }  
        });  
        this.$message.success('验证码发送成功');  

        // 开始倒计时  
        this.startCountdown();  
      } catch (error) {  
        this.$message.error('验证码发送失败');  
      }  
    },
    startCountdown() {  
      if (this.isCountdown) return; // 如果已经在倒计时，则直接返回  

      this.isCountdown = true;  
      const timer = setInterval(() => {  
        this.countdown--;  
        if (this.countdown <= 0) {  
          clearInterval(timer);  
          this.countdown = 60;  
          this.isCountdown = false;  
        }  
      }, 1000);  
    },
    async smsLogin() {
      // if (!this.smsLoginForm.agree) {
      //   return this.$message.error('请同意用户协议和隐私政策');
      // }
      var that=this
      try {
        const response = await apiClient.get('/verifyCode', {
          params: {
            phone: this.smsLoginForm.phone,
            code: this.smsLoginForm.code
          }
        });
        localStorage.username= this.smsLoginForm.phone
        localStorage.token=response.data.token
        
      
        this.$emit('login-success');
        setTimeout(() => {
         that.socket = that.$createSocket();
         that.socket.onopen = () => {
           console.log('WebSocket 连接已建立');
         };
         that.socket.onmessage = (event) => {
           console.log(`接收到消息：${event.data}`);
           var json=event.data
           json=JSON.parse(json)
           if (json.newLoginUserId==localStorage.username&&localStorage.token!=''){
            localStorage.token=''
            window.location = '/';
           }
           
         };
         that.socket.onerror = (error) => {
          setTimeout(() => {
          that.connectSocket();
        }, 3000);
           console.error('WebSocket 错误：', error);
         };
         that.socket.onclose = () => {
          setTimeout(() => {
          that.connectSocket();
        }, 3000);
           console.log('WebSocket 连接已关闭');
         };
        }, 3000);
        this.$message.success('登录成功');
        eventBus.$emit('goAiHuanzhuang', 'AIModelLibrary');
        // 处理成功登录的逻辑，例如保存 token 并跳转
      } catch (error) {
        this.$message.error('登录失败');
      }
    },
    async passwordLogin() {
      var that=this
      try {
        const response = await apiClient.get('/login', {
          params: {
            username: this.passwordLoginForm.phone,
            password: this.passwordLoginForm.password
          }
        });
        localStorage.token=response.data.token
        localStorage.username=this.passwordLoginForm.phone
        this.$emit('login-success');
        this.$message.success('登录成功');
        setTimeout(() => {
         that.socket = that.$createSocket();
         that.socket.onopen = () => {
           console.log('WebSocket 连接已建立');
         };
         that.socket.onmessage = (event) => {
           console.log(`接收到消息：${event.data}`);
           var json=event.data
           json=JSON.parse(json)
           if (json.newLoginUserId==localStorage.username&&localStorage.token!=''){
            localStorage.token=''
            window.location = '/';
           }
           
         };
         that.socket.onerror = (error) => {
          setTimeout(() => {
          that.connectSocket();
        }, 3000);
           console.error('WebSocket 错误：', error);
         };
         that.socket.onclose = () => {
          setTimeout(() => {
          that.connectSocket();
        }, 3000);
           console.log('WebSocket 连接已关闭');
         };
        }, 3000);
        eventBus.$emit('goAiHuanzhuang', 'AIModelLibrary');
        // 处理成功登录的逻辑，例如保存 token 并跳转
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$message.error('用户未找到');
        } else if (error.response && error.response.status === 401) {
          this.$message.error('密码错误');
        } else {
          this.$message.error('登录失败');
        }
      }
    }
  }
};
</script>

<style scoped>
.login-dialog ::v-deep .el-dialog{
  border-radius: 10px;
}
.login-dialog ::v-deep .el-dialog__body {
  padding: 0;
}
.login-dialog ::v-deep .el-dialog__header {
  display: none;
}
.dialog-content {
  display: flex;
  height: 500px;
}
.left-panel {
  flex: 1;
  padding: 40px 20px;
  /* background: linear-gradient(to bottom right, #e0f7fa, #80deea, #2352D8); */
  background-image: url('https://huanyingyichu.oss-cn-shanghai.aliyuncs.com/loginbg.png');
  /* background-image: url('@/assets/loginbg.png'); */
  border-radius: 10px 0 0 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.content {
  color: #000;
  padding-left: 30px;
}
.logo {
  width: 100px;
  height: auto;
  margin-bottom: 60px;
}
h2 {
  font-size: 24px;
  margin: 10px 0;
}
h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 0 auto; /* 将列表居中 */
}
ul li {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.currect {
  width: 15px;
  height: auto;
  margin-right: 5px;
}
.right-panel {
  flex: 1.5;
  /* padding: 40px 20px; */
}
.closediv {
  text-align: right;
  padding: 10px;
}
.login-form {
  padding-top: 30px;
  max-width: 300px;
  margin: 0 auto;
  width: 100%; /* 使表单宽度自适应 */
}
.custom-input .el-input__inner {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  box-sizing: border-box;
}
.custom-input .el-input__inner:focus {
  border-color: #2352D8;
}
.custom-button {
  background-color: #2352D8;
  color: white;
  border-radius: 5px;
  height: 40px;
  font-size: 14px;
  padding: 0 20px;
}
.custom-button:hover {
  background-color: #66b1ff;
}
.custom-checkbox {
  font-size: 12px;
}
.custom-tabs {
  padding: 0 40px;
}
.custom-tabs /deep/ .el-tabs__header.is-top {
  display: flex;
  justify-content: center;
}
.custom-tabs /deep/ .el-tabs__item {
  font-size: 18px;
  color: #000;
  height: 50px;
  margin-top: 5px;
}
.custom-tabs /deep/ .el-tabs__active-bar {
  width: 40px !important;
  left: 8% !important;
  background-color: #4765fd;
}
.custom-tabs ::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
.code-input {
  display: flex;
}
.code-input .el-input {
  flex: 2;
}
.code-input .el-button {
  flex: 1;
  margin-left: 10px;
}
.login-button {
  width: 100%;
  background: #2352D8;
  border-radius: 40px;
  margin-top: 30px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog-close {
  font-size: 18px;
  cursor: pointer;
}
.loginbtn {
  position: absolute;
  right: 12px;
}
</style>
  