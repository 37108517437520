<template>
  <div class="shoes">
    <CustomerUpload
      name="鞋子上脚"
      text="上传鞋子商品图"
      demand="要求:上传鞋子正侧/外侧图片"
      :actionUrl="urlAction"
      :username="username"
      :originalUrl="originalUrl"
      :cutdownUrl="cutdownUrl"
      @fileSuccess="chengong"
    />
    <!-- <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible">
      <MoteMore v-if="imgMoreVisible" @close="handleCloseMore" @childSelected="childSelected"
        :imgList="imgList1" :title="moreTitle" @selectClothesType="selectClothesType"
      />
        <div class="selectmodelclass1" slot="reference" @click="openbtn">
          <div class="iconcontain" :style="{ backgroundColor: moteurl1 === '' ? '#efefef' : 'transparent' }">
            <img v-if="moteurl1 == ''" src="@/assets/iconbg.png" />
            <img v-else :src="moteurl1" style="height: auto; width: 100%; border-radius: 5px" />
          </div>
          <div class="xuanzetext">
            <span class="xzmt">选择模特</span>
            <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
          </div>
        </div>
    </el-popover> -->
    <el-upload
      class="upload-demo"
      :action="urlAction3"
      :data="{ username: username }"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="chengong1"
    >
      <div class="selectmodelclass2" @click="handleCloseMore">
        <div class="iconcontain" :style="{ backgroundColor: moteurl2 === '' ? '#efefef' : 'transparent' }">
          <img v-if="moteurl2 == ''" src="@/assets/iconbg.png" />
          <img v-else :src="moteurl2" style="height: auto; width: 100%; border-radius: 5px" />
        </div>
        <div class="uploadmotetext">
          <span class="xzmt">上传自己的模特</span>
          <i class="el-icon-arrow-right" style="font-size: 22px; float: right" />
        </div>
      </div>
    </el-upload>
    <!-- <div class="generatenum">
      <el-input v-model="num" type="number" :min="1" :max="4" disabled>
        <template slot="prepend">{{ $t('shengchengshuliang') }}</template>
      </el-input>
    </div> -->
    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:2</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import MoteMore from './MoteMore.vue';
import CustomerUpload from './CustomerUpload.vue';
import apiClient from '@/services/api';
import mote1 from '@/assets/mote4.jpg';
import mote2 from '@/assets/mote3.jpg';
import mote3 from '@/assets/mote5.jpg';
import mote4 from '@/assets/mote6.jpg';
import mote5 from '@/assets/mote7.jpg';
import mote6 from '@/assets/mote8.jpg';
import mote7 from '@/assets/mote9.png';

export default {
  components: { CustomerUpload, MoteMore },
  data() {
    return {
      imgList1: [
        [
          mote1,
          mote2,
          mote3,
          mote4,
          mote5,
          mote6,
          mote7,
        ],
      ],
      imgMoreVisible: false,
      urlAction: window._CONFIG['domianURL']+'upload1',
      urlAction3: window._CONFIG['domianURL']+'upload3',
      username: localStorage.username,
      originalUrl: '',
      cutdownUrl: '',
      moteurl1: '',
      moteurl2: '',
      num: 1,
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
    }
  },
  created() {},
  mounted() {
    this.loadingdata1('全部');
  },
  methods: {
    AIGenerate() {
      if (this.originalUrl == '') {
        this.$message.error(this.$t('xiezibuweikong'));
        return false
      }
      if (this.cutdownUrl == '') {
        this.$message.error(this.$t('xiezibuweikong'));
        return false
      }
      if (this.moteurl1 == '' && this.moteurl2 == '') {
        this.$message.error(this.$t('motetupianbuweikong'));
        return false
      }
      const obj = {
        originalUrl: this.originalUrl,
        cutdownUrl: this.cutdownUrl,
        moteurl1: this.moteurl1,
        moteurl2: this.moteurl2,
        moteurl: this.moteurl2 !== '' ?  this.moteurl2 : this.moteurl1
      };
      this.$emit('AIGenerate', obj);
    },
    chengong(epath, bpath) {
      this.originalUrl = epath;
      this.cutdownUrl = bpath;
    },
    chengong1(response, file, fileList) {
      let mypath = response.filePath;
      mypath = mypath.replace('output', '');
      const epath = window._CONFIG['domianURL'] + mypath;
      this.moteurl2 = epath;
      this.moteurl1 = '';
    },
    childSelected(value) {
      this.moteurl1 = value;
      this.moteurl2 = '';
    },
    openbtn() {
      this.imgMoreVisible = true;
      this.moreTitle = {
        bigTitle: this.$t('suijimote'),
        remark: this.$t('suijimoteshuoming'),
        name: [''],
        qvisible: true,
      }
    },
    handleCloseMore() {
      this.imgMoreVisible = false;
    },
    selectClothesType(value) {
      this.loadingdata1(value);
    },
    async loadingdata1(value) {
      const response = await apiClient.get(`/getmodels66`, {
        params: {
          page: 1,
          limit: 44,
          continent: '',
          gender: '',
          leixing: '青年',
          dsex: '全部',
          darea: '全部',
          fushi: value,
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      this.totalImagesCount += mylist.length;
      this.imgList1[0] = []
      const tempArr = [];
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = window._CONFIG['domianURL'];
        let contentpaht = item.image_path;
        contentpaht = contentpaht.replace("./output", "");
        let path1 = path + contentpaht;
        item.image_path = path1;
        tempArr.push(path1);
      }
      this.$set(this.imgList1, 0, tempArr);
    },
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPGorPNG) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      if (!isLt5M) {
        this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
        return false;
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            if (img.width >= 400 && img.height >= 400) {
              resolve(true);
            } else {
              this.$message.error('图片大小小于5MB，分辨率大于400*400，格式支持JPG、PNG  格式');
              reject(false);
            }
          };
        };
      });
    },
  },
}
</script>

<style scoped>
.shoes{
  text-align: center;
}
.selectmodelclass1 {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.selectmodelclass1:hover {
  background: rgba(101, 126, 185, 0.08);
}
.iconcontain {
  height: 100px;
  width: 80px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.xuanzetext {
  width: calc(100% - 80px);
  text-align: left;
  font-size: 14px;
  color: #303133;
}
.xzmt {
  margin-left: 30px;
}
.upload-demo {
  height: 100px;
  font-size: 14px;
  color: #303133;
  margin-top: 10px;
  border-radius: 5px;
}
.upload-demo /deep/ .el-upload {
  width: 100%;
  text-align: left;
}
.selectmodelclass2 {
  display: flex;
  align-items: center;
}
.selectmodelclass2:hover {
  background: rgba(101, 126, 185, 0.08);
}
.uploadmotetext {
  width: calc(100% - 80px);
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>