<template>
  <div class="container" v-if="allResourcesLoaded">
    <el-dialog :visible.sync="imgPreviewVisible" width="80%" :modal-append-to-body="true"
      class="previewdialog" style="text-align: center">
      <span slot="title">
        <span style="margin-right: 20px">任务ID-{{historyBack.id}}</span>
        <span style="color: #808080">{{historyBack.create_time}}</span>
      </span>
      <div style="height: 100%; width: 100%; display: flex">
        <div class="yulanmote">
          <div v-if="currentMenu === '1'" class="smalltag">
            <el-tag v-if="historyBack.posture && historyBack.posture !== ''" style="height: 20px; line-height: 20px">AI模特</el-tag>
            <el-tag v-if="historyBack.models2 && historyBack.models2 !== ''" style="height: 20px; line-height: 20px">我的模特</el-tag>
          </div>
          <div v-if="currentMenu === '2' || currentMenu === '3'" class="smalltag">
            <el-tag style="height: 20px; line-height: 20px">AI模特</el-tag>
          </div>
          <div v-if="currentMenu === '1'">
            <img v-if="historyBack.posture && historyBack.posture !== ''" :src="historyBack.posture" />
            <img v-if="historyBack.models2 && historyBack.models2 !== ''" :src="historyBack.models2" />
          </div>
          <img v-if="currentMenu === '2' || currentMenu === '3'" :src="historyBack.models" />
        </div>
        <div class="yulanyuantu">
          <div class="smalltag">
            <el-tag style="height: 20px; line-height: 20px">原图</el-tag>
          </div>
          <div v-if="historyBack.type && historyBack.type.includes('多件')" class="yulanduojian">
            <div class="duojian1">
              <img :src="historyBack.img2" />
            </div>
            <div class="duojian2">
              <img :src="historyBack.img3" />
            </div>
          </div>
          <div v-else class="danjian1">
            <img :src="historyBack.img1" />
          </div>
        </div>
        <div class="yulanshengtu">
          <div ref="panzoomElement" class="previewdiv">
            <img :src="vpath1" />
            <div class="dialog_loading" v-if="isLoadingXiaoguo && imgPreviewVisible">
              <div>
                  <i class="el-icon-loading" style="font-size: 22px"></i>
                  <div style="margin-top: 5px">{{ loadingText }}</div>
                </div>
            </div>
          </div>
          <div class="tagdiv">
            <el-tag style="height: 20px; line-height: 20px">生成图</el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isLoadingXiaoguo">
        <div
          style="display: flex; align-items: center; justify-content: space-around; background:#EFF1F6; border-radius: 5px 0 0 5px; line-height: 1; padding: 1px 0">
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修脸'}" v-if="Number(currentMenu) <= '7' &&currentMenu != '4'" @click="lianbuxiufubd" @mouseenter="iconEnter('修脸')" @mouseleave="iconSelected = null">
            <img src="@/assets/lian11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('xiulian') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修手'}" v-if="Number(currentMenu) <= '7' &&currentMenu != '4'" @click="shoubuxiufubd" @mouseenter="iconEnter('修手')" @mouseleave="iconSelected = null">
            <img src="@/assets/shou11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>修手</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '扩图'}" @click="kuotubd" @mouseenter="iconEnter('扩图')" @mouseleave="iconSelected = null">
            <img src="@/assets/kuotu2.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('kuotu') }}</span>
          </div>
        </div>
        <el-button type="primary" class="uploadbtn" @click="downloadImage">
          <img src="@/assets/xiazai2.png" style="height: 12px; width: 15px" />
          {{ $t('xiazaitupian') }}
        </el-button>
        <div class="sizeicon">
          <img src="@/assets/reset3.png" style="height: 25px; width: 25px; cursor: pointer;" @click="resetZoom" />
          <i class="el-icon-minus" style="font-size: 25px; cursor: pointer;" @click="zoomOut"></i>
          <span>{{ scalePercent }}%</span>
          <i class="el-icon-plus" style="font-size: 25px; cursor: pointer;" @click="zoomIn"></i>
        </div>
      </span>
    </el-dialog>
    <el-dialog :title="$t('tupianyulan')" :visible.sync="imgPreviewVisible2" width="60%" :modal-append-to-body="true"
      class="previewdialog" style="text-align: center">
      <div style="height: 100%; width: 100%">
        <div ref="panzoomElement" class="previewdiv">
          <img :src="vpath1" />
          <div class="dialog_loading" v-if="isLoadingXiaoguo && imgPreviewVisible2">
            <div>
                <i class="el-icon-loading" style="font-size: 22px"></i>
                <div style="margin-top: 5px">{{ loadingText }}</div>
              </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isLoadingXiaoguo">
        <div
          style="display: flex; align-items: center; justify-content: space-around; background:#EFF1F6; border-radius: 5px 0 0 5px; line-height: 1; padding: 1px 0">
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修脸'}" v-if="Number(currentMenu) <= '7' && currentMenu!='4'" @click="lianbuxiufubd" @mouseenter="iconEnter('修脸')" @mouseleave="iconSelected = null">
            <img src="@/assets/lian11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('xiulian') }}</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '修手'}" v-if="Number(currentMenu) <= '7' && currentMenu!='4'" @click="shoubuxiufubd" @mouseenter="iconEnter('修手')" @mouseleave="iconSelected = null">
            <img src="@/assets/shou11.png" style="height:20px; width:20px; margin-right:5px" />
            <span>修手</span>
          </div>
          <div class="yulanIconDiv" :class="{'selecticon': iconSelected === '扩图'}" @click="kuotubd" @mouseenter="iconEnter('扩图')" @mouseleave="iconSelected = null">
            <img src="@/assets/kuotu2.png" style="height:20px; width:20px; margin-right:5px" />
            <span>{{ $t('kuotu') }}</span>
          </div>
        </div>
        <el-button type="primary" class="uploadbtn" @click="downloadImage">
          <img src="@/assets/xiazai2.png" style="height: 12px; width: 15px" />
          {{ $t('xiazaitupian') }}
        </el-button>
        <div class="sizeicon">
          <img src="@/assets/reset3.png" style="height: 25px; width: 25px; cursor: pointer;" @click="resetZoom" />
          <i class="el-icon-minus" style="font-size: 25px; cursor: pointer;" @click="zoomOut"></i>
          <span>{{ scalePercent }}%</span>
          <i class="el-icon-plus" style="font-size: 25px; cursor: pointer;" @click="zoomIn"></i>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="历史任务" :visible.sync="allHistoryVisible" width="82%" :modal-append-to-body="true"
      class="historydialog" style="text-align: center">
      <el-row v-for="(group, groupIndex) in groupedData" :key="groupIndex">
        <el-col v-for="(item, index) in group" :key="index" class="dialogcol">
          <div
            class="carddiv"
            :class="{'lishiselected': (saveHistoryIndex === item.id || saveHistoryIndex1 === item.id)}"
            @click="handleHistory(item)"
            @mouseenter="enterItem(item)"
            @mouseleave="leaveItem"
          >
            <div style="text-align: left; color: #000; margin-bottom: 5px">任务ID-{{item.id}}</div>
            <div style="text-align: left; color: #808080">{{item.create_time}}</div>
            <el-image
              class="lishidialogimg"
              :src="item.imgJieguo"
            >
              <div slot="placeholder" class="imgloading">
                <img :src="placeholderImage" style="width: 100%; height: auto" />
              </div>
              <div slot="error" @click="handleHistory(item)" style="height: 100%">
                <!-- <i class="el-icon-loading" style="margin-top: 40px"></i>
                <div style="font-size: 14px; color: #303313">生成中...</div> -->
                <img :src="errorImage" style="width: 100%; height: auto" />
              </div>
            </el-image>
          </div>
          <div class="dialogdel" @click="delHistory(item)">
            <i class="el-icon-delete" style="font-size: 22px; color: #fff" />
          </div>
        </el-col>
      </el-row>
      <el-pagination
        slot="footer"
        background
        layout="prev, pager, next"
        :total="historyAll.length"
        :current-page="historyCurrentPage"
        :page-size="historyPageSize"
        @current-change="currentChange"
        style="text-align: center"
      >
      </el-pagination>
    </el-dialog>
    <div style="width: 130px;">
      <el-menu :default-active="currentMenu" @select="selectMenu" class="el-menu-vertical-demo" ref="cdmenu">

        <el-menu-item index="1">
          <img src="@/assets/aiIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('aihuanzhuang') }}</span>
        </el-menu-item>
        <el-menu-item index="2">
          <img src="@/assets/rentaiIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('rentaitu') }}</span>
        </el-menu-item>
        <el-menu-item index="3">
          <img src="@/assets/banlv1.png" class="menuicon" />
          <span slot="title">{{ $t('zhenrentu') }}</span>
        </el-menu-item>
        <el-menu-item index="6">
          <img src="@/assets/xieziIcon1.png" class="menuicon" style="width: 20px;" />
          <span slot="title">{{ $t('xiezi') }}</span>
        </el-menu-item>
        <el-menu-item index="4">
          <img src="@/assets/shangpinIcon1.png" class="menuicon" />
          <span slot="title">{{ $t('shangpintu') }}</span>
        </el-menu-item>
        <el-menu-item index="5">
          <img src="@/assets/moteIcon1.png" class="menuicon" />
          <span slot="title">{{ $t('motexunlian') }}</span>
        </el-menu-item>
<!--        <el-menu-item index="7">-->
<!--          &lt;!&ndash; <i class="el-icon-menu"></i> &ndash;&gt;-->
<!--          <img src="@/assets/lianbu1.png" class="menuicon" style="width: 20px" />-->
<!--          <span slot="title">{{ $t('lianbuxunlian') }}</span>-->
<!--        </el-menu-item>-->
        <el-menu-item index="8">
          <!-- <img src="@/assets/tongkuan.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/gaikuan1.png" class="menuicon" />
          <span slot="title">{{ $t('fushigaikuan') }}</span>
        </el-menu-item>
        <el-menu-item index="9">
          <!-- <img src="@/assets/081.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/wenshengkuan.png" class="menuicon" />
          <span slot="title">{{ $t('wenshengkuan') }}</span>
        </el-menu-item>
        <el-menu-item index="10">
          <!-- <img src="@/assets/huaxing.png" class="menuicon" style="max-width:120px" /> -->
          <img src="@/assets/huaxingIcon1.png" class="menuicon" style="width: 22px; margin-left: 3px" />
          <span slot="title">{{ $t('huaxing') }}</span>
        </el-menu-item>
        <el-menu-item index="11">
          <!-- <img src="@/assets/fengyi.png" class="menuicon" style="max-width:200px" /> -->
          <img src="@/assets/xiangao.png" class="menuicon" style="width: 20px" />
          <span slot="title">{{ $t('xiangao') }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div v-if="currentMenu === '1'" class="box">
      <AIHuanzhuang ref="aiHuanzhuang" @generate1="huianyi1" @generate="huianyi" :historyBack="historyBack" :paidui="paidui" />
    </div>
    <!-- 人台图 -->
    <div v-if="currentMenu === '2'" class="box">
      <Rentaitu ref="rentaitu" :imgList3="imgList3" :imgList4="imgList4" :imgList5="imgList5" :historyBack="historyBack" :paidui="paidui" @AIGenerate="AIGenerate" />
    </div>
    <!-- 真人图 -->
    <div v-if="currentMenu === '3'" class="box">
      <ZhenRenTu ref="zhenrentu" :imgList3="imgList3" :imgList4="imgList4" :imgList5="imgList5" :historyBack="historyBack" :paidui="paidui" @AIGenerate="AIGenerate" />
    </div>
    <!-- 商品图 -->
    <div v-if="currentMenu === '4'" class="box">
      <ShangPinTu ref="shangpintu" :cjImgList1="cjImgList1" :cjImgList2="cjImgList2" @AIGenerate="AIGenerate" />
    </div>
    <!-- 模特训练 -->
    <div v-if="currentMenu === '5'" class="box">
      <ModelTraining @AIGenerate1="AIGenerate1" />
    </div>
    <!-- 鞋子上脚 -->
    <div v-if="currentMenu === '6'" class="box">
      <Shoes ref="shoes" @AIGenerate="AIGenerate" />
    </div>
    <!-- 脸部训练 -->
    <div v-if="currentMenu === '7'" class="box">
      <FaceTraining @AIGenerate2="AIGenerate2" />
    </div>
    <!-- 服饰改款 -->
    <div v-if="currentMenu === '8'" class="box">
      <ClothingRedesign :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" @AIGenerate3="AIGenerate3" />
    </div>
    <!-- 文生款 -->
    <div v-if="currentMenu === '9'" class="box">
      <TextGenerated :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" @AIGenerate4="AIGenerate4" />
    </div>
    <!-- 花型衍生 -->
    <div v-if="currentMenu === '10'" class="box">
      <HuaXingYanSheng @AIGenerate5="AIGenerate5" />
    </div>
    <!-- 线稿生款 -->
    <div v-if="currentMenu === '11'" class="box">
      <XianGao :colorlist="colorlist" :kuanxinglist="kuanxinglist" :sexlist="sexlist" :mianliaolist="mianliaolist" @AIGenerate6="AIGenerate6" />
    </div>
    <!-- 生成结果 -->
    <div style="flex-grow: 1;text-align: center; background-color: #eef1f7" @click="handleEmpty">
      <template>
        <div class="zhanshiqu">
          <div class="xiaoguodiv">
            <div style="text-align: left; margin-bottom: 10px; font-size: 14px; color: #303133">
              生成结果
              <span style="color: #999999;font-size: 12px; margin-left: 10px">{{jieguoTime}}</span>
            </div>
            <div class="xiaoguotu">
              <!-- 加载图标 -->
              <div class="loading_overlay" v-if="isLoadingXiaoguo">
                <div>
                  <i class="el-icon-loading"></i>
                  <div style="margin-top: 5px">{{ loadingText }}</div>
                </div>
              </div>

              <div v-show="vpath1 === '' && !isLoadingXiaoguo" class="image_container">
                <div>
                  <img src="@/assets/xiaoguobg.png" style="width: 240px; height: auto" />
                  <div>{{ $t('gaopinzhi') }}</div>
                </div>
              </div>
              <!-- 生成后的效果 -->
              <div class="image_container">
                <img style="height: 100%; width: 100%; object-fit: contain; border-radius: 10px" :src="vpath1" v-if="vpath1 != ''" />
                <div class="overlay" v-show="vpath1 != ''">
                  <div class="zoomindiv" @click="zoomImage">
                    <i class="el-icon-zoom-in" style="font-size: 30px; color: #fff;"></i>
                  </div>
                  <div class="botton_icon">
                    <img src="@/assets/lian22.png" v-if="Number(currentMenu) <= '7'&&currentMenu != '4'"
                      style="height:30px; width: 30px; margin: 0 7px" @click="lianbuxiufubd" />
                    <img src="@/assets/shou22.png" v-if="Number(currentMenu) <= '7'&&currentMenu != '4'"
                      style="height:26px; width: 26px; margin: 0 7px" @click="shoubuxiufubd" />
                    <!-- <img src="@/assets/icon2.png" style="height:30px; width: 30px; margin: 0 7px" @click="kuotubd" /> -->
                    <img src="@/assets/kuotu1.png" style="height:26px; width: 26px; margin: 0 7px" @click="kuotubd" />
                    <img src="@/assets/xiazai2.png" style="height: 23px; width: 25px; margin: 0 7px" @click="downloadImage" />
                    <!-- <i class="el-icon-download" @click="downloadImage"
                      style="font-size: 30px; cursor: pointer; color: #fff; margin: 0 7px"></i> -->
                    <!-- <img src="@/assets/rentai.png" v-if="currentMenu == '7'"
                      style="height:30px; width: 30px; margin: 0 15px" @click="gotorentai" />
                    <img src="@/assets/zhenren.png" v-if="currentMenu == '7'"
                      style="height:30px; width: 30px; margin: 0 15px" @click="gotozhenrentu" /> -->
                    <img src="@/assets/huanzhuang.png" v-if="currentMenu == '5'"
                      style="height:30px; width: 30px; margin: 0 7px" @click="gotohuanzhuang" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lishicontain" v-if="historyList.length !== 0">
            <div style="display: flex; justify-content: left; align-items: center; margin-bottom: 10px">
              <img src="@/assets/lishiIcon.png" style="height: 16px; width: auto; margin-right: 5px" />
              <span style="font-size: 14px; color: #303133">历史任务</span>
            </div>
            <div class="lishitu">
              <div v-for="(item, index) in historyList" :key="index" class="lishidiv">
                <el-image
                  :src="item.imgJieguo"
                  class="lishiimg"
                  :class="{'lishiselected': (saveHistoryIndex === item.id || saveHistoryIndex1 === item.id)}"
                  @click="handleHistory(item)"
                  @mouseenter="enterItem(item)"
                  @mouseleave="leaveItem"
                  :lazy="true"
                >
                  <div slot="placeholder" class="imgloading">
                    <img src="@/assets/loading11.gif" style="width: 100%; height: auto" />
                  </div>
                  <div slot="error" class="image-slot" @click="handleHistory(item)">
                    <i class="el-icon-loading" style="margin-top: 40px"></i>
                    <div style="font-size: 14px; color: #303313">生成中...</div>
                  </div>
                </el-image>
                <div class="lishiimgtext" @click="delHistory(item)">
                  <i class="el-icon-delete" style="color: #fff" />
                </div>
              </div>
            </div>
            <div class="showAll" >
              <el-button @click="handleAllHistory" style="width: 135px; border-radius: 0; border: 0"  v-if="historyAll.length > 10">查看全部</el-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <PricingModal :visible.sync="showModal" />

  </div>
</template>

<script>
import TextGenerated from './TextGenerated.vue';
import FaceTraining from './FaceTraining.vue';
import ModelTraining from './ModelTraining.vue';
import XianGao from './XianGao.vue';
import HuaXingYanSheng from './HuaXingYanSheng.vue';
import ClothingRedesign from './ClothingRedesign.vue';
import Shoes from './Shoes.vue';
import ShangPinTu from './ShangPinTu.vue';
import ZhenRenTu from './ZhenRenTu.vue';
import Rentaitu from './Rentaitu.vue';
import AIHuanzhuang from './AIHuanzhuang.vue';
import Panzoom from '@panzoom/panzoom';
import apiClient from '@/services/api'; // 假设你有一个 apiClient 处理请求
import axios from 'axios';
import PricingModal from './PricingModal.vue';
import ImageMore from './ImageMore.vue';

export default {
  name: 'HorizontalContainers',
  props: ['menuIndex'],
  components: {
    PricingModal,
    ImageMore,
    AIHuanzhuang,
    Rentaitu,
    ZhenRenTu,
    ShangPinTu,
    Shoes,
    ClothingRedesign,
    HuaXingYanSheng,
    XianGao,
    ModelTraining,
    FaceTraining,
    TextGenerated,
  },
  data() {
    return {
      iconSelected: null,
      isGenerateChangeTask: false,
      historyDialogData: [],
      historyCurrentPage: 1,
      historyPageSize: 10,
      allHistoryVisible: false,
      historyAll: [],
      xiangaoCount: 0,
      huaxingCount: 0,
      wenshengCount: 0,
      gaikuanCount: 0,
      moteCount: 0,
      xieziCount: 0,
      shangpinCount: 0,
      zhenrenCount: 0,
      rentaiCount: 0,
      huanyiCount: 0,
      huanyi1Count: 0,
      paidui:false,
      isGenerateChangeMenu: false, // 生成时候切换菜单
      saveHistoryIndex1: null,
      saveHistoryIndex: null,
      historyBack: {},
      historyList: [],
      mianliaolist: [
        this.$t('pige'),
        this.$t('quanmao'),
        this.$t('pimaoyiti'),
        this.$t('diaopi'),
        this.$t('kelirong'),
        this.$t('jipirong'),
        this.$t('maorong'),
        this.$t('picao'),
        this.$t('niuzaimianliao'),
        this.$t('leisi'),
        this.$t('maonimianliao'),
        this.$t('dengxinrong'),
        this.$t('mianmamianliao'),
        this.$t('sichoumianliao'),
        this.$t('zhengzhimianliao'),
        this.$t('fanghugongneng'),
        this.$t('zhengzhuangmianliao'),
        this.$t('leisimianliao'),
        this.$t('mianfangmianliao'),
        this.$t('mafangmianliao'),
        this.$t('xuefang'),
        this.$t('shizhuanghuaxianmianliao'),
        this.$t('yundonghuaxianmianliao'),
        this.$t('hunfangmianliao'),
        this.$t('sezhitiaogemianliao'),
        this.$t('cuhua'),
        this.$t('zhenzhimaonimiaoliao'),
        this.$t('jingfangmianliao'),
        this.$t('sirongmianliao'),
        this.$t('yinhuamianliao'),
        this.$t('tihuamianliao'),
        this.$t('litizhuangshimianliao'),
        this.$t('teshumianliao'),
        this.$t('yangrong'),
        this.$t('mahaimao')
      ],
      sexlist: [this.$t('nvzhuang'), this.$t('nanzhuang'), this.$t('tongzhuang')],
      kuanxinglist: [
        [
          this.$t('shangyi'),
          this.$t('tixu'),
          this.$t('weiyi'),
          this.$t('chenshan'),
          this.$t('taoshan'),
          this.$t('kaishan'),
          this.$t('xiaokaishan'),
          this.$t('poloshan'),
          this.$t('beixin'),
          this.$t('dadishan'),
          this.$t('diaodai'),
          this.$t('moxiong'),
        ],
        [
          this.$t('mianyurong'),
          this.$t('mianyurongjiake'),
          this.$t('zhongchangmianyurong'),
          this.$t('yurongmajia'),
          this.$t('qingbaoyurong'),
          this.$t('yurongku'),
          this.$t('huaxuefu'),
        ],
        [
          this.$t('waitao'),
          this.$t('dayi'),
          this.$t('jiake'),
          this.$t('pijiake'),
          this.$t('xizhuang'),
          this.$t('fengyi'),
          this.$t('pifengyi'),
          this.$t('fangshaifu'),
          this.$t('paikefu'),
          this.$t('chongfengyi'),
          this.$t('majia'),
          this.$t('doupeng'),
        ],
        [
          this.$t('qun'),
          this.$t('lianyiqun'),
          this.$t('banshenqun'),
          this.$t('beidaiqun'),
        ],
        [
          this.$t('ku'),
          this.$t('zhongchangku'),
          this.$t('duankuo'),
          this.$t('weiku'),
          this.$t('liantiku'),
          this.$t('dadiku'),
          this.$t('beidaiku'),
          this.$t('yujiaoku'),
          this.$t('piku'),
        ],
        [
          this.$t('taozhuang'),
          this.$t('xizhuangtaozhuang'),
          this.$t('yundongtaozhuang'),
          this.$t('weiyitaozhuang'),
          this.$t('shishangtaozhuang'),
          this.$t('qinzizhuang'),
          this.$t('xueyuanfengtaozhuang'),
        ],
        [
          this.$t('jiajuzhuang'),
          this.$t('jiajufu'),
          this.$t('shuiyitaozhuang'),
          this.$t('shuiqun'),
          this.$t('shuipao'),
          this.$t('shuiku'),
          this.$t('shuiyidiaodai'),
          this.$t('shuiyi'),
          this.$t('yunfuzhuang'),
        ],
        [
          this.$t('neiyi'),
          this.$t('wenxiong'),
          this.$t('neiku'),
          this.$t('wenxiongtaozhuang'),
          this.$t('baonuanxilie'),
          this.$t('sushenyi'),
        ],
        [
          this.$t('hunsha'),
          this.$t('Azixinghunsha'),
          this.$t('yuweihunsha'),
          this.$t('zhishenhunsha'),
        ],
        [
          this.$t('lifu'),
          this.$t('xiaolifu'),
          this.$t('wanlifu'),
          this.$t('qipao'),
          this.$t('xiuhefu'),
        ],
        [
          this.$t('yongzhuang'),
          this.$t('liantiyongyi'),
          this.$t('fentiyongyi'),
          this.$t('shatanqun'),
          this.$t('bijini'),
          this.$t('shatanku'),
          this.$t('yongku'),
          this.$t('chonglangyongyi'),
        ],
        [
          this.$t('yingerfu'),
          this.$t('baopiyi'),
          this.$t('papafu'),
        ],
        [
          this.$t('fushipin'),
          this.$t('maozi'),
          this.$t('yifumao'),
          this.$t('bangqiumao'),
          this.$t('zhengzhimao'),
          this.$t('shishangmao'),
          this.$t('zheyangmao'),
          this.$t('weijin'),
          this.$t('sijin'),
          this.$t('zhengzhiweijin'),
          this.$t('shoutao'),
          this.$t('pishoutao'),
          this.$t('zhengzhishoutao'),
          this.$t('shishangshoutao'),
          this.$t('wazi'),
          this.$t('duanwa'),
          this.$t('tongwa'),
          this.$t('siwa'),
          this.$t('liankuwa'),
          this.$t('lingdai'),
          this.$t('lingjie'),
          this.$t('yaodai'),
        ],
        [
          this.$t('yingtongyongpin'),
          this.$t('lihe'),
          this.$t('koushuidou'),
          this.$t('baobaowa'),
          this.$t('yingermao'),
          this.$t('shuidai'),
        ],
        [
          this.$t('chongwuyongpin'),
          this.$t('chongwufushi'),
          this.$t('chongwuzhoubian')
        ]
      ],
      colorlist: [
        this.$t('qianfense'),
        this.$t('fenhongse'),
        this.$t('meihongse'),
        this.$t('hongse'),
        this.$t('anhongse'),
        this.$t('mise'),
        this.$t('tuose'),
        this.$t('huangse'),
        this.$t('jinse'),
        this.$t('lise'),
        this.$t('kafeise'),
        this.$t('ganlanlv'),
        this.$t('lvse'),
        this.$t('molvse'),
        this.$t('qianlanse'),
        this.$t('lanse'),
        this.$t('shenlanse'),
        this.$t('qianzise'),
        this.$t('zise'),
        this.$t('shenzise'),
        this.$t('baise'),
        this.$t('qianhuise'),
        this.$t('zhonghuise'),
        this.$t('shenhuise'),
        this.$t('heise'),
      ],
      panzoomInstance: null,
      scalePercent: 100,
      isLoadingXiaoguo: false,
      loadingText: '',
      imgPreviewVisible: false,
      imgPreviewVisible2: false,
      //监测人台图
      prompts: '',
      hairstyles: [
        "A stunning young lady with flowing brown hair and a radiant smile.",
        "Female, long, curly hair.",
        "Female, long curly hair.",
        `Female, long straight hair.`,
        "Long, straight hair, with a thick braid, looks chic and sophisticated.",
        "For women, comb their hair into a bun.",
        "Short hair, shaggy, looks modern and sophisticated.",
        "Female, short straight hair, ear length.",
        "Female, short straight hair, chin length.",
        `Female, short hair to shoulder length.`,
        `The man has a neatly styled modern haircut, with side parting and the top slicked back smoothly.`,
        `Male short hair, shaggy, center part, no bangs curly.`,
        `Male, short hair, curly hair, no bangs.`,
        `Male, Korean male hairstyle.`,
        `Male, male buzz cut, very short,brush cut.`
      ],
      changjinglist: [
        'Studio Shoot', 'Street Shoot', 'Beach', 'White Studio Shoot', 'Black Studio Shoot',
        'Gray Studio Shoot', 'European Street Shoot', 'Japanese Street Shoot', 'Chinese Street Shoot',
        'Forest', 'Mountain', 'Cherry Blossoms', 'Park', 'Fashion Show', 'Farm', 'Lawn', 'Party',
        'Swimming Pool', 'Gym', 'Library', 'Space', 'Dock', 'Home', 'Church', 'Amusement Park',
        'Colorful', 'Snowy Scene', 'Subway', 'Garage', 'Stairs', 'Graffiti', 'Street', 'Platform',
        'European Bedroom', 'Instagram Bedroom'
      ],

      biaoqinglist: ['Poker face','smile','shy','joyful','guffaw', 'aloof', 'anger'],
      defaulttx: ['girl1', 'girl2', 'girl3', 'boy1', 'boy2', 'boy3'],
      cjImgList2: [
        [
          {
            url: window._CONFIG['domianURL']+'changjing/cj21.jpg',
            name: this.$t('xianhua')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj22.jpg',
            name: this.$t('chunjie')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj23.jpg',
            name: this.$t('meihua')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj24.jpg',
            name: this.$t('sichou')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj25.jpg',
            name: this.$t('luori')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj26.jpg',
            name: this.$t('shanlin')
          }
        ],
      ],
      cjImgList1: [
        [
          {
            url: window._CONFIG['domianURL']+'changjing/cj11.jpg',
            name: this.$t('xuedi')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj12.jpg',
            name: this.$t('kafeiting')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj13.jpg',
            name: this.$t('bingchuan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj14.jpg',
            name: this.$t('liantian')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj15.jpg',
            name: this.$t('haishui')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cj16.jpg',
            name: this.$t('haitan')
          }
        ],
      ],
      imgMoreVisible5: false,
      saveChangjing: '',
      imgList5: [
        [
          {
            url: window._CONFIG['domianURL']+'changjing/pengpai.jpg',
            name: this.$t('pengpai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/jiepai.jpg',
            name: this.$t('jiepai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/haitan.jpg',
            name: this.$t('haitan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/baisepengpai.jpg',
            name: this.$t('baisepeng')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/heisepengpai.jpg',
            name: this.$t('heisepeng')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/huisepengpai.jpg',
            name: this.$t('huisepeng')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/oushi.jpg',
            name: this.$t('oushijiepai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/rishi.jpg',
            name: this.$t('rishijiepai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/zhongshi.jpg',
            name: this.$t('zhongshijiepai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/senlin.jpg',
            name: this.$t('senlin')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/gaoshan.jpg',
            name: this.$t('gaoshan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/yinghua.jpg',
            name: this.$t('yinghua')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/gongyuan.jpg',
            name: this.$t('gongyuan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/xiuchang.jpg',
            name: this.$t('xiuchang')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/nongchang.jpg',
            name: this.$t('nongchang')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/caoping.jpg',
            name: this.$t('caoping')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/juhui.jpg',
            name: this.$t('juhui')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/yongchi.jpg',
            name: this.$t('yongchi')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/jianshenfang.jpg',
            name: this.$t('jianshenfang')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/tushuguan.jpg',
            name: this.$t('tushuguan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/taikong.jpg',
            name: this.$t('taikong')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/matou.jpg',
            name: this.$t('matou')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/jujia.jpg',
            name: this.$t('jujia')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/jiaotang.jpg',
            name: this.$t('jiaotang')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/youleyuan.jpg',
            name: this.$t('youleyuan')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/duocai.jpg',
            name: this.$t('duocai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/xuejing.jpg',
            name: this.$t('xuejing')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/ditie.jpg',
            name: this.$t('ditie')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/cheku.jpg',
            name: this.$t('cheku')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/louti.jpg',
            name: this.$t('louti')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/tuya.jpg',
            name: this.$t('tuya')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/jietou.jpg',
            name: this.$t('jietou')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/zhantai.jpg',
            name: this.$t('zhantai')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/oushiwoshi.jpg',
            name: this.$t('oufengwoshi')
          },
          {
            url: window._CONFIG['domianURL']+'changjing/inswoshi.jpg',
            name: this.$t('inswoshi')
          }
        ]
      ]
      ,
      imgMoreVisible4: false,
      saveBiaoqing: '',
      imgList4: [
        [
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing1.jpg',
            name: this.$t('wubiaoqing')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing2.jpg',
            name: this.$t('weixiao')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing3.jpg',
            name: this.$t('haixiu')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing4.jpg',
            name: this.$t('xiyue')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing5.jpg',
            name: this.$t('daxiao')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing6.jpg',
            name: this.$t('gaoleng')
          },
          {
            url: window._CONFIG['ossUrl'] + '/biaoqing7.jpg',
            name: this.$t('shengqi')
          },

        ],
      ],
      imgList3: [
        [
          window._CONFIG['ossUrl'] + '/faxing1.jpg',
          window._CONFIG['ossUrl'] + '/faxing2.jpg',
          window._CONFIG['ossUrl'] + '/faxing3.jpg',
          window._CONFIG['ossUrl'] + '/faxing4.jpg',
          window._CONFIG['ossUrl'] + '/faxing5.jpg',
          window._CONFIG['ossUrl'] + '/faxing6.jpg',
          window._CONFIG['ossUrl'] + '/faxing7.jpg',
          window._CONFIG['ossUrl'] + '/faxing8.jpg',
          window._CONFIG['ossUrl'] + '/faxing9.jpg',
          window._CONFIG['ossUrl'] + '/faxing10.jpg',
          window._CONFIG['ossUrl'] + '/faxing11.png',
          window._CONFIG['ossUrl'] + '/faxing12.jpg',
          window._CONFIG['ossUrl'] + '/faxing13.jpg',
          window._CONFIG['ossUrl'] + '/faxing14.jpg',
          window._CONFIG['ossUrl'] + '/faxing15.jpg',
        ],
      ],
      currentMenu: '1',
      allResourcesLoaded: false,
      showModal: false,
      value: '',
      myloading: '',
      vpath1: '',
      vpath: '',
      page: 1,
      limit: 10,
      username: localStorage.username,
      url1: window._CONFIG['domianURL']+'gd/00055_00.jpg',
      furl1: window._CONFIG['domianURL']+'gd/00055_00.jpg',
      url2: window._CONFIG['domianURL']+'gd/00126_00.jpg',
      url3: window._CONFIG['domianURL']+'gd/00151_00.jpg',
      url4: window._CONFIG['domianURL']+'gd/00470_00.jpg',
      lurl1: window._CONFIG['domianURL']+'wj/051515_1.jpg',
      lurl2: window._CONFIG['domianURL']+'wj/051517_1.jpg',
      lurl3: window._CONFIG['domianURL']+'wj/051827_1.jpg',
      lurl4: window._CONFIG['domianURL']+'wj/051946_1.jpg',
      surl1: window._CONFIG['domianURL']+'1719969857625.png',
      surl2: window._CONFIG['domianURL']+'1719812281699.png',
      surl3: window._CONFIG['domianURL']+'1719812878787.png',
      moteurl: window._CONFIG['domianURL']+'moren/weishangchuan.png',
      yurl1: window._CONFIG['domianURL']+'1719812281699.png',
      dpath: '',
      lturl1: window._CONFIG['domianURL']+'lianti/053742_1.jpg',
      lturl2: window._CONFIG['domianURL']+'lianti/053744_1.jpg',
      lturl3: window._CONFIG['domianURL']+'lianti/053786_1.jpg',
      lturl4: window._CONFIG['domianURL']+'lianti/053790_1.jpg',
      urlAction:window._CONFIG['domianURL']+'upload1',
      urlAction1:window._CONFIG['domianURL']+'upload',
      urlAction3:window._CONFIG['domianURL']+'upload3',
      jieguoTime:'',
      hasDingshi:false,
      max_id:'',
      select_id:'',
      xiulianPath:'',
      placeholderImage: require('@/assets/loading22.png'),
      errorImage: require('@/assets/loading22.png'),
    };
  },
  computed: {
    groupedData() {
      const groupSize = 5;
      const result = [];
      for (let i = 0; i < this.historyDialogData.length; i += groupSize) {
        result.push(this.historyDialogData.slice(i, i + groupSize));
      }
      return result;
    },
  },
  async mounted() {
    if (this.menuIndex) {
      this.selectMenu(this.menuIndex)
    } else {
      const historyList = await this.getHistoryList(this.currentMenu);
      this.historyList = historyList;
      this.dingshi();
    }
    this.myloading = this.$loading({
      lock: true,
      text: "正在加载中请稍等~",
      // spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.2)"
    });

    this.$nextTick(() => {
      this.checkAllResourcesLoaded();
    });

  },
  beforeDestroy() {


  },
  methods: {
    iconEnter(val) {
      this.iconSelected = val;
    },
    currentChange(current) {
      this.historyCurrentPage = current;
      this.historyDialogList(this.currentMenu);
    },
    handleAllHistory() {

      if(this.currentMenu==2||this.currentMenu==3){
        this.placeholderImage = require('@/assets/loading11.gif');
        this.errorImage = require('@/assets/loading11.gif');
      }else{
        this.placeholderImage = require('@/assets/loading22.png');
        this.errorImage = require('@/assets/loading22.png');
      }

      this.allHistoryVisible = true;
      if (this.isLoadingXiaoguo) {
        this.isGenerateChangeTask = true;
      }
      this.historyCurrentPage = 1;
      this.historyDialogList(this.currentMenu);
    },
    handleEmpty() {
      if (this.currentMenu === '1') {
        this.$refs.aiHuanzhuang.handleCloseMore();
      }
      if (this.currentMenu === '2') {
        this.$refs.rentaitu.handleCloseMore();
      }
      if (this.currentMenu === '3') {
        this.$refs.zhenrentu.handleCloseMore();
      }
      if (this.currentMenu === '4') {
        this.$refs.shangpintu.handleCloseMore();
      }
      if (this.currentMenu === '6') {
        this.$refs.shoes.handleCloseMore();
      }
    },
    delHistory(value) {
      this.$confirm('一旦删除将无法复原，请再次确认', '您确定要删除吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const response = await apiClient.get(`/delHistory?id=${value.id}`);
          if (response.status === 200) {
            // 刷新历史列表
            this.historyList = await this.getHistoryList(this.currentMenu);
            this.historyDialogList(this.currentMenu);
            // 清空回显
            if (this.vpath1 === value.imgJieguo) {
              this.vpath1 = '';
              this.historyBack = {
                type: '',
                banxing: '',
                img1: '',
                img1k: '',
                img2: '',
                img2k: '',
                img3: '',
                img3k: '',
              };
              this.jieguoTime=''
            }
            this.$message.success('删除成功');
            this.isLoadingXiaoguo = false;
          }
        } catch (error) {
          this.$message.error('删除失败');
        }
      }).catch(() => {

      });
    },
    async delHistoryNoPermission(value) {
      const response = await apiClient.get(`/delHistory?id=${value.id}`);
      this.historyList = await this.getHistoryList(this.currentMenu);
    },
    async handleHistory(value,type) {
      console.log("进入了handleHistory");
      //type有值时  代表扩图或者修脸、修手等自动跳转
      if(!type){
        this.isGenerateChangeTask = true;
        if(this.paidui){
          this.$message.warning('排队中~请等待2秒再继续操作');
          return  false;
        }
      }
      if (this.allHistoryVisible) {
        this.allHistoryVisible = false;
      }
      // const showimg = await this.getHistoryList(this.currentMenu, value.id);
      const showimg = this.historyAll.find(it => it.id === value.id);
      this.select_id=value.id;
      console.log("all",this.historyAll);
      console.log("showimg",showimg);
      if(!showimg) return false;
      if(showimg.is_running=='1'){
        this.vpath1 ='';
        this.jieguoTime ='';
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
      }else{
        this.vpath1 = showimg.imgJieguo;
        this.jieguoTime = showimg.create_time;
        this.isLoadingXiaoguo = false;
      }
      console.log('=======historyBack', showimg)
      this.historyBack = showimg;
      // 选中历史图标增加边框
      this.saveHistoryIndex1 = value.id;

    },
    enterItem(value) {
      this.saveHistoryIndex = value.id;
    },
    leaveItem() {
      this.saveHistoryIndex = null;
    },
    async getHistoryList(module, id) {
      const url = id ? `getHistory?module=${module}&id=${id}` : `getHistory?module=${module}&user=${localStorage.username}`
      const res = await apiClient.get(url);
      this.historyAll = res.data.data;
      const historyShow = res.data.data.slice(0, 10);
      // this.historyDialogData = historyShow;
      // this.historyCurrentPage = 1;
      return historyShow;
    },
    async historyDialogList(module, id) {
      const url = id ? `getHistory?module=${module}&id=${id}&page_no=${this.historyCurrentPage}&page_size=${this.historyPageSize}`
        : `getHistory?module=${module}&user=${localStorage.username}&page_no=${this.historyCurrentPage}&page_size=${this.historyPageSize}`;
      const res = await apiClient.get(url);
      // this.historyAll = res.data.data;
      // const historyShow = res.data.data.slice(0, 10);
      // this.historyDialogData = historyShow;
      this.historyDialogData = res.data.data;
    },
    zoomIn() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomIn();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    zoomOut() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomOut();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    resetZoom() {
      if (this.panzoomInstance) {
        this.panzoomInstance.reset();
        this.updateScalePercent(); // 更新缩放百分比
      }
    },
    updateScalePercent() {
      // 获取当前的缩放比例
      const currentScale = this.panzoomInstance.getScale();
      // 计算百分比，并四舍五入保留两位小数
      this.scalePercent = Math.round(currentScale * 100);
    },
    async kuotubd() {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
      }
      var that = this
      this.paidui=true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.paidui=true;
      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        const base64Image = await this.convertImageToBase64(this.vpath1);
        that.vpath1 = '';
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": base64Image
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var qpath = responseComupload.data.name
        var requestData2 = {"img1": qpath}
        const responset2 = await apiClient.post('/compromptkuotu', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        that.historyBack.client_id = prompt_id;
        var value=JSON.parse(JSON.stringify(that.historyBack));
        if(this.currentMenu=='1'||this.currentMenu=='2'||this.currentMenu=='3'){
          value.fuwuqi='comfytask8288kuotu'
          value.ip=''
          value.module=this.currentMenu;
          await that.insertHistory(value);
          setTimeout(()=>{
            value.id=that.max_id;
          },5000)
          that.handleHistory({id:this.max_id},'1')
        }else{
          value.module=this.currentMenu;
          this.paidui=false;
        }
        var t1 = setInterval(async function () {
          try {
            var taskid = prompt_id
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: taskid,
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}'){
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1&&status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                const imagesForEight = outputsForId?.["153"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                var response2 = await apiClient.get('/tdownloadmyurl', {
                  params: {
                    url2: window._CONFIG['baseUrl23_8288']+`api/view?filename=${filename}&subfolder=&type=output&rand=0.3484368289500308`,
                  }
                })
                var path2 = response2.data.outputPath
                var vpathr = window._CONFIG['domianURL'] + path2.replace('./output/', '')

                let bili='1'
                if(value.module=='2'||value.module=='3'){
                  bili='2'
                }
                var vpath=await that.xianshangImg(vpathr,bili);
                value.imgJieguo=vpath;
                if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
                  that.vpath1 = vpath
                }
                var tresponse1 = await apiClient.get('/inserrlist', {
                  params: {
                    resulturl: that.vpath1,
                    username: localStorage.username,
                  }
                });
                that.historyBack.imgJieguo = vpath;
                that.koujian('7',value)
                if (value.module===that.currentMenu) {
                  that.isLoadingXiaoguo = false;
                }

                if(!that.vpath1&&value.id==that.select_id){
                  that.vpath1 = vpath
                  const showimg = that.historyAll.find(it => it.id === value.id);
                  that.jieguoTime = showimg.create_time;
                }

              }
            }
          }catch (e) {
            console.log(e);
            that.$message.error("生成失败~");
            that.vpath1='';
            that.isLoadingXiaoguo = false;
            that.delHistoryNoPermission({id:that.max_id});
            clearInterval(t1)
            return false;
          }

        }, 2000)
      }catch (e) {
        this.$message.error("生成失败~");
        this.vpath1='';
        if(that.max_id)  this.delHistoryNoPermission({id:that.max_id});
        this.isLoadingXiaoguo = false;
      }

    },
    async shoubuxiufubd(fuwuqi) {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
      }
      var that = this
      this.paidui=true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      if(fuwuqi!='107'){
        this.xiulianPath=this.vpath1;
      }
      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        const base64Image = await this.convertImageToBase64(fuwuqi=='107'?this.xiulianPath:this.vpath1);
        this.vpath1 = '';
        var imagurl1 = base64Image
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": imagurl1,
          "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var qpath = responseComupload.data.name
        var requestData2 = {"img1": qpath,"fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''}
        const responset2 = await apiClient.post('/compromptxiushou', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        that.historyBack.client_id = prompt_id;
        var value=JSON.parse(JSON.stringify(that.historyBack));
        if(this.currentMenu=='1'||this.currentMenu=='2'||this.currentMenu=='3'){
          value.fuwuqi='comfytask8288xiushou'
          value.ip=fuwuqi=='107'?'baseUrl107_8188':'';
          value.module=this.currentMenu;
          await that.insertHistory(value);
          setTimeout(()=>{
            value.id=that.max_id;
          },5000)
          that.handleHistory({id:this.max_id},'1')
        }else{
          value.module=this.currentMenu;
          this.paidui=false;
        }

        var t1 = setInterval(async function () {
          try {
            var taskid = prompt_id
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: taskid,
                "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}'){
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1&&status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                const imagesForEight = outputsForId?.["3"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                var response2 = await apiClient.get('/tdownloadmyurl', {
                  params: {
                    url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&type=temp&subfolder`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&type=temp&subfolder`,
                  }
                })
                var path2 = response2.data.outputPath
                var vpathr = window._CONFIG['domianURL'] + path2.replace('./output/', '')

                let bili='1'
                if(value.module=='2'||value.module=='3'){
                  bili='2'
                }
                var vpath=await that.xianshangImg(vpathr,bili);
                value.imgJieguo=vpath;
                if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
                  that.vpath1 = vpath
                }
                that.historyBack.imgJieguo = vpath;
                that.koujian('7',value)
                var tresponse1 = await apiClient.get('/inserrlist', {
                  params: {
                    resulturl: that.vpath1,
                    username: localStorage.username,
                  }
                });
                if (value.module===that.currentMenu) {
                  that.isLoadingXiaoguo = false;
                }
                if(!that.vpath1&&value.id==that.select_id){
                  that.vpath1 = vpath
                  const showimg = that.historyAll.find(it => it.id === value.id);
                  that.jieguoTime = showimg.create_time;
                }
              }
            }
          }catch (e) {
            if(fuwuqi!='107'){
              that.delHistoryNoPermission({id:that.max_id});
              clearInterval(t1);
              that.shoubuxiufubd('107');
              return false;
            }{
              console.log(e);
              that.$message.error("生成失败~");
              that.vpath1='';
              that.isLoadingXiaoguo = false;
              that.delHistoryNoPermission({id:that.max_id});
              clearInterval(t1)
              return false;
            }
          }

        }, 2000)
      }catch (e) {
        if(fuwuqi!='107'){
          if(that.max_id)  this.delHistoryNoPermission({id:that.max_id});
          this.shoubuxiufubd('107');
        }else{
          this.$message.error("生成失败~");
          this.vpath1='';
          if(that.max_id)  this.delHistoryNoPermission({id:that.max_id});
          this.isLoadingXiaoguo = false;
        }
      }

    },
    async lianbuxiufubd(fuwuqi) {
      if (this.imgPreviewVisible || this.imgPreviewVisible2) {
        this.resetZoom();
      }
      var that = this
      this.paidui=true;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      if(fuwuqi!='107'){
       this.xiulianPath=this.vpath1;
      }
      try {
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 3) {
          this.$message.error("您的换装套餐已经用完~")
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = 'AI图形正在生成中，请耐心等待1-2分钟~';
        const base64Image = await this.convertImageToBase64(fuwuqi=='107'?this.xiulianPath:this.vpath1);
        this.vpath1 = '';
        var imagurl1 = base64Image
        var headers = {
          'x-access-token': localStorage.token
        }
        var requestData = {
          "imageUrl": imagurl1,
          "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
        }
        const responseComupload = await apiClient.post('/comupload8288', requestData, {headers});
        var qpath = responseComupload.data.name
        var requestData2 = {"img1": qpath,"fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''}
        const responset2 = await apiClient.post('/compromptxiulianFast', requestData2, {headers});
        var prompt_id = responset2.data.prompt_id
        that.historyBack.client_id = prompt_id;
        var value=JSON.parse(JSON.stringify(that.historyBack));
        if(this.currentMenu=='1'||this.currentMenu=='2'||this.currentMenu=='3'){
          value.fuwuqi='comfytask8288xiulian'
          value.ip=fuwuqi=='107'?'baseUrl107_8188':'';
          value.module=this.currentMenu;
          await that.insertHistory(value);
          setTimeout(()=>{
            value.id=that.max_id;
          },5000)
          that.handleHistory({id:this.max_id},'1')
        }else{
          value.module=this.currentMenu;
          this.paidui=false;
        }

        var t1 = setInterval(async function () {
          try {
            var taskid = prompt_id
            var tresponse1 = await apiClient.get('/comfytask8288', {
              params: {
                prompt_id: taskid,
                "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
              },
              headers: {
                'x-access-token': localStorage.token
              }
            });
            var data = tresponse1.data
            if(JSON.stringify(data) != '{}'){
              const specificId = prompt_id;
              const status1 = data[specificId]?.status
              if (status1&&status1.completed == true) {
                clearInterval(t1)
                const outputsForId = data[specificId]?.outputs;
                const imagesForEight = outputsForId?.["7"]?.images;
                const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
                var response2 = await apiClient.get('/tdownloadmyurl', {
                  params: {
                    url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&type=temp&subfolder`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&type=temp&subfolder`,
                  }
                })

                var path2 = response2.data.outputPath
                var vpathr = window._CONFIG['domianURL'] + path2.replace('./output/', '')

                let bili='1'
                if(value.module=='2'||value.module=='3'){
                  bili='2'
                }
                var vpath=await that.xianshangImg(vpathr,bili);
                value.imgJieguo=vpath;
                if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
                  that.vpath1 = vpath
                }
                that.historyBack.imgJieguo = vpath;
                that.koujian('7',value)
                var tresponse1 = await apiClient.get('/inserrlist', {
                  params: {
                    resulturl: that.vpath1,
                    username: localStorage.username,

                  }
                });
                if (value.module===that.currentMenu) {
                  that.isLoadingXiaoguo = false;
                }
                if(!that.vpath1&&value.id==that.select_id){
                  that.vpath1 = vpath
                  const showimg = that.historyAll.find(it => it.id === value.id);
                  that.jieguoTime = showimg.create_time;
                }
              }
            }
          }catch (e) {
            if(fuwuqi!='107'){
              that.delHistoryNoPermission({id:that.max_id});
              clearInterval(t1);
              that.lianbuxiufubd('107');
              return false;
            }{
              console.log(e);
              that.$message.error("生成失败~");
              that.vpath1='';
              that.isLoadingXiaoguo = false;
              that.delHistoryNoPermission({id:that.max_id});
              clearInterval(t1)
              return false;
            }
          }

        }, 2000)
      }catch (e) {
        if(fuwuqi!='107'){
          if(that.max_id)  this.delHistoryNoPermission({id:that.max_id});
          this.lianbuxiufubd('107');
        }else{
          this.$message.error("生成失败~");
          this.vpath1='';
          if(that.max_id)  this.delHistoryNoPermission({id:that.max_id});
          this.isLoadingXiaoguo = false;
        }
      }

    },
    async AIGenerate2(val) {
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading')
      try {
        const response = await apiClient.get('/generateModel4', {
          params: {
            continent: val.value,
            gender: val.value1,
            ageRange: val.value2,
            expression: val.value3,
            prompts: val.prompts,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {
          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.$message.error(that.$t('motetushibai'))
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl1', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,
              }
            });
            clearInterval(t1)
            if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = that.dpath
            }
            that.koujian('4')
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async AIGenerate4(value) {
      // 防止过快点击过次生成
      this.wenshengCount += 1;
      if (this.wenshengCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      const requestData = {
        promptText: value.prompts,
        color: value.mycolor,
        categoryAndName: value.mydanpin,
        gender: value.mysex,
        fabric: value.mymianliao,
      };
      var headers = {
        'x-access-token': localStorage.token
      }
      const response = await apiClient.post('/imageToImage1', requestData, { headers });
      if (response.data.message === 'image successfully') {
        this.$message.success('生成完毕');
        this.isLoadingXiaoguo = false
        var path2 = response.data.outputPath
        var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
        if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
          that.vpath1 = vpath
        }
        that.wenshengCount = 0;
        that.koujian('2')
        var tresponse1 = await apiClient.get('/inserrlist', {
          params: {
            resulturl: that.vpath1,
            username: localStorage.username,
          }
        });
        // 可以在这里处理返回的 outputPath，比如显示图片等操作
      } else {
        this.$message.error(this.$t('gaikuanshibai'));
        this.isLoadingXiaoguo = false
      }
    },
    async AIGenerate3(value) {
      // 防止过快点击过次生成
      this.gaikuanCount += 1;
      if (this.gaikuanCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      const requestData = {
        promptText: value.prompts,
        color: value.mycolor,
        categoryAndName: value.mydanpin,
        gender: value.mysex,
        fabric: value.mymianliao,
        Strength: value.tvalue,
        InputImage: value.original,
      };
      // 调用改款 API
      var headers = {
        'x-access-token': localStorage.token
      }
      const response = await apiClient.post('/imageToImage', requestData, { headers });
      if (response.data.message === 'image successfully') {
        this.$message.success(this.$t('gaikuanchenggong'));
        this.isLoadingXiaoguo = false
        var path2 = response.data.outputPath
        var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
        if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
          that.vpath1 = vpath
        }
        that.gaikuanCount = 0;
        that.koujian('2')
        var tresponse1 = await apiClient.get('/inserrlist', {
          params: {
            resulturl: that.vpath1,
            username: localStorage.username,
          }
        });
        // 可以在这里处理返回的 outputPath，比如显示图片等操作
      } else {
        this.$message.error(this.$t('gaikuanshibai'));
        this.isLoadingXiaoguo = false
      }
    },
    async AIGenerate6(value) {
      // 防止过快点击过次生成
      this.xiangaoCount += 1;
      if (this.xiangaoCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      const requestData = {
        promptText: value.prompts,
        color: value.mycolor,
        categoryAndName: value.mydanpin,
        gender: value.mysex,
        fabric: value.mymianliao,
        Strength: value.tvalue,
        InputImage: value.original,
      };
      // 调用改款 API
      var headers = {
        'x-access-token': localStorage.token
      }
      const response = await apiClient.post('/imageToImage3', requestData, { headers });
      if (response.data.message === 'image successfully') {
        this.$message.success(this.$t('gaikuanchenggong'));
        this.isLoadingXiaoguo = false
        var path2 = response.data.outputPath
        var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
        if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
          that.vpath1 = vpath
        }
        that.xiangaoCount = 0;
        that.koujian('2')
        var tresponse1 = await apiClient.get('/inserrlist', {
          params: {
            resulturl: that.vpath1,
            username: localStorage.username,
          }
        });
        // 可以在这里处理返回的 outputPath，比如显示图片等操作
      } else {
        this.$message.error(this.$t('gaikuanshibai'));
        this.isLoadingXiaoguo = false
      }
    },
    async AIGenerate5(value) {
      // 防止过快点击过次生成
      this.huaxingCount += 1;
      if (this.huaxingCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 4) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      const requestData = {
        promptText: value.prompts,
        Strength: value.tvalue,
        InputImage: value.original,
      };
      // 调用改款 API
      var headers = {
        'x-access-token': localStorage.token
      }
      const response = await apiClient.post('/imageToImage2', requestData, { headers });
      if (response.data.message === 'image successfully') {
        this.$message.success(this.$t('gaikuanchenggong'));
        this.isLoadingXiaoguo = false
        var path2 = response.data.outputPath
        var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
        if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
          that.vpath1 = vpath
        }
        that.huaxingCount = 0;
        that.koujian('2')
        // 可以在这里处理返回的 outputPath，比如显示图片等操作
      } else {
        this.$message.error(this.$t('gaikuanshibai'));
        this.isLoadingXiaoguo = false
      }
    },
    async AIGenerate1(val) {
      // 防止过快点击过次生成
      this.moteCount += 1;
      if (this.moteCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 2) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      try {
        const response = await apiClient.get('/generateModel3zhongwen', {
          params: {
            continent: val.value,
            gender: val.value1,
            ageRange: val.value2,
            expression: val.value3,
            prompts: val.prompts,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var taskId = response.data.taskId
        var t1 = setInterval(async function () {
          var tresponse1 = await apiClient.get('/gettask', {
            params: {
              taskId: taskId,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.$message.error(that.$t('motetushibai'))
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpatht = window._CONFIG['domianURL'] + path2.replace('./output/', '')
            var vpath =await that.resultXiulian(vpatht);

            that.dpath = vpath
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.dpath,
                username: localStorage.username,
              }
            });
            clearInterval(t1)
            if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = that.dpath
            }
            that.moteCount = 0;
            that.koujian('3')
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      } catch (error) {
        console.error('Error generating model:', error);
      }
    },
    async AIGenerate(value) {
      var that = this
      this.saveHistoryIndex1 = null;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.jieguoTime='';
      var predefined_face_id = ''
      var face_image_url = ''
      var model_description = ''
      var background_description = ''
      this.vpath = ''
      this.vpath1 = ''
      this.handleEmpty();

      if (this.currentMenu === '2') {
        // 防止过快点击过次生成
        this.paidui=true;
        this.rentaiCount += 1;
        if (this.rentaiCount !== 1) {
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        if (value.saveMote.indexOf("http") > -1) {
          this.isLoadingXiaoguo = true;
          this.loadingText = this.$t('aituxingloading');
          const response = await apiClient.get('/upload-image', {

            params: {
              imageUrl: value.saveMote
            },
            headers: {
              'x-access-token': localStorage.token
            }
          });
          face_image_url = response.data.url
          this.isLoadingXiaoguo = false;
        }
        else {
          var eindex = 0
          if (value.saveMote.indexOf('p810525') > -1) {
            eindex = 0
          }
          if (value.saveMote.indexOf('p810527') > -1) {
            eindex = 1
          }
          if (value.saveMote.indexOf('p810528') > -1) {
            eindex = 2
          }
          if (value.saveMote.indexOf('p810526') > -1) {
            eindex = 3
          }
          if (value.saveMote.indexOf('p810522') > -1) {
            eindex = 4
          }
          if (value.saveMote.indexOf('p810524') > -1) {
            eindex = 5
          }
          predefined_face_id = this.defaulttx[eindex]
        }
        var eindex = 0
        if (value.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (value.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (value.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (value.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (value.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (value.saveMote.indexOf('p810524') > -1) {
          eindex = 5
        }
        model_description = this.defaulttx[eindex]
        var eindex = 0
        if (value.saveChangjing.indexOf('pengpai') > -1) {
          eindex = 0
        }
        if (value.saveChangjing.indexOf('jiepai') > -1) {
          eindex = 1
        }
        if (value.saveChangjing.indexOf('hai') > -1) {
          eindex = 2
        }
        if (value.saveChangjing.indexOf('baise') > -1) {
          eindex = 3
        }
        if (value.saveChangjing.indexOf('heise') > -1) {
          eindex = 4
        }
        if (value.saveChangjing.indexOf('huise') > -1) {
          eindex = 5
        }
        if (value.saveChangjing.indexOf('oushi') > -1) {
          eindex = 6
        }
        if (value.saveChangjing.indexOf('rishi') > -1) {
          eindex = 7
        }
        if (value.saveChangjing.indexOf('zhongshi') > -1) {
          eindex = 8
        }
        if (value.saveChangjing.indexOf('senlin') > -1) {
          eindex = 9
        }
        if (value.saveChangjing.indexOf('gaoshan') > -1) {
          eindex = 10
        }
        if (value.saveChangjing.indexOf('yinghua') > -1) {
          eindex = 11
        }
        if (value.saveChangjing.indexOf('gongyuan') > -1) {
          eindex = 12
        }
        if (value.saveChangjing.indexOf('xiuchang') > -1) {
          eindex = 13
        }
        if (value.saveChangjing.indexOf('nongchang') > -1) {
          eindex = 14
        }
        if (value.saveChangjing.indexOf('cao') > -1) {
          eindex = 15
        }
        if (value.saveChangjing.indexOf('juhui') > -1) {
          eindex = 16
        }
        if (value.saveChangjing.indexOf('yongchi') > -1) {
          eindex = 17
        }
        if (value.saveChangjing.indexOf('jianshen') > -1) {
          eindex = 18

        }
        if (value.saveChangjing.indexOf('tushu') > -1) {
          eindex = 19
        }
        if (value.saveChangjing.indexOf('taikong') > -1) {
          eindex = 20
        }
        if (value.saveChangjing.indexOf('matou') > -1) {
          eindex = 21
        }
        if (value.saveChangjing.indexOf('jujia') > -1) {
          eindex = 22
        }
        if (value.saveChangjing.indexOf('jiao') > -1) {
          eindex = 23
        }
        if (value.saveChangjing.indexOf('youle') > -1) {
          eindex = 24
        }
        if (value.saveChangjing.indexOf('duocai') > -1) {
          eindex = 25
        }
        if (value.saveChangjing.indexOf('xuejing') > -1) {
          eindex = 26
        }
        if (value.saveChangjing.indexOf('ditie') > -1) {
          eindex = 27
        }
        if (value.saveChangjing.indexOf('cheku') > -1) {
          eindex = 28
        }
        if (value.saveChangjing.indexOf('louti') > -1) {
          eindex = 29
        }
        if (value.saveChangjing.indexOf('tuiya') > -1) {
          eindex = 30
        }
        if (value.saveChangjing.indexOf('jietou') > -1) {
          eindex = 31
        }
        if (value.saveChangjing.indexOf('zhantai') > -1) {
          eindex = 32
        }
        if (value.saveChangjing.indexOf('oushiwoshi') > -1) {
          eindex = 33
        }
        if (value.saveChangjing.indexOf('inswoshi') > -1) {
          eindex = 34
        }
        var myindex = 0
        if (value.saveFaxing.indexOf('faxing1') > -1) {
          myindex = 0
        }
        if (value.saveFaxing.indexOf('faxing2') > -1) {
          myindex = 1
        }
        if (value.saveFaxing.indexOf('faxing3') > -1) {
          myindex = 2
        }
        if (value.saveFaxing.indexOf('faxing4') > -1) {
          myindex = 3
        }
        if (value.saveFaxing.indexOf('faxing5') > -1) {
          myindex = 4
        }
        if (value.saveFaxing.indexOf('faxing6') > -1) {
          myindex = 5
        }
        if (value.saveFaxing.indexOf('faxing7') > -1) {
          myindex = 6
        }
        if (value.saveFaxing.indexOf('faxing8') > -1) {
          myindex = 7
        }
        if (value.saveFaxing.indexOf('faxing9') > -1) {
          myindex = 8
        }
        if (value.saveFaxing.indexOf('faxing10') > -1) {
          myindex = 9
        }
        if (value.saveFaxing.indexOf('faxing11') > -1) {
          myindex = 10
        }
        if (value.saveFaxing.indexOf('faxing12') > -1) {
          myindex = 11
        }
        if (value.saveFaxing.indexOf('faxing13') > -1) {
          myindex = 12
        }
        if (value.saveFaxing.indexOf('faxing14') > -1) {
          myindex = 13
        }
        if (value.saveFaxing.indexOf('faxing15') > -1) {
          myindex = 14
        }
        var hairstyles = this.hairstyles[myindex]
        var eindex = 0
        if (value.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (value.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (value.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (value.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (value.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (value.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        if (value.saveBiaoqing.indexOf('biaoqing7') > -1) {
          eindex = 6
        }
        var biaoqing = this.biaoqinglist[eindex]
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        background_description = this.changjinglist[eindex]
        const response3 = await apiClient.get('/upload-image', {

          params: {
            imageUrl: value.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url
        const response = await apiClient.get('/upload-image', {
          params: {
            imageUrl: value.originalUrl
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        let base_image = response.data.url;
        const params = {
          base_image_url: base_image,
          mask_image_url: value.cutdownUrl,
          predefined_face_id: predefined_face_id,
          background_image_url: background_image_url,
          face_image_url: face_image_url,
          model_description: model_description,
          background_description: background_description,
          aspect_ratio: value.radio === that.$t('yuanshi') ? '1:1' : value.radio,
          hairstyle_description: hairstyles,
          biaoqing: biaoqing
        };

        const res = await apiClient.get(`/getrentaitu`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        var taskid = res.data.output.task_id
        this.rentaiCount = 0;
        that.historyBack.client_id = taskid;
        value.client_id = taskid;
        value.img1 = value.originalUrl;
        value.models = value.saveMote;
        value.img1k = value.cutdownUrl;
        value.faxing = value.saveFaxing;
        value.biaoqing = value.saveBiaoqing;
        value.changjing = value.saveChangjing;
        value.bili = value.radio;
        value.module=this.currentMenu;
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },5000)
        var t1 = setInterval(async function () {

          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.paidui=false;
            that.$message.error("人台图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpatht = window._CONFIG['domianURL'] + path2.replace('./output/', '')

            var vpathr ='';
            try {
              vpathr =await that.resultXiulian(vpatht);
            }catch (e) {
              try {
                vpathr =await that.resultXiulian(vpatht,'107');
              }catch (e) {
                that.$message.error('生成失败')
                that.delHistoryNoPermission({id:that.max_id});
                that.paidui=false;
                that.isLoadingXiaoguo = false;
                return false;
              }
            }

            var vpath=await that.xianshangImg(vpathr,'2');


            value.imgJieguo = vpath;
            if (value.module==that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = vpath
              that.historyBack = value;
            }
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,
              }
            });
            that.koujian('2',value)
            if (value.module==that.currentMenu){
              that.isLoadingXiaoguo = false;
            }

            if(!that.vpath1&&value.id==that.select_id){
              that.vpath1 = vpath
              that.historyBack = value;
              const showimg = that.historyAll.find(it => it.id === value.id);
              that.jieguoTime = showimg.create_time;
            }
          }
        }, 8000)


      }
      if (this.currentMenu === '3') {
        // 真人图
        // 防止过快点击过次生成
        this.paidui=true;
        this.zhenrenCount += 1;
        if (this.zhenrenCount !== 1) {
          this.$message.warning('排队中~请等待2秒再继续操作');
          return;
        }
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 4) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        if (value.saveMote.indexOf("http") > -1) {
          this.isLoadingXiaoguo = true;
          this.loadingText = this.$t('aituxingloading');
          //随机模特
          const response = await apiClient.get('/upload-image', {
            params: {
              imageUrl: value.saveMote
            },
            headers: {
              'x-access-token': localStorage.token
            }
          });
          face_image_url = response.data.url
          this.isLoadingXiaoguo = false;
        }
        else {
          var eindex = 0
          if (value.saveMote.indexOf('p810525') > -1) {
            eindex = 0
          }
          if (value.saveMote.indexOf('p810527') > -1) {
            eindex = 1
          }
          if (value.saveMote.indexOf('p810528') > -1) {
            eindex = 2
          }
          if (value.saveMote.indexOf('p810526') > -1) {
            eindex = 3
          }
          if (value.saveMote.indexOf('p810522') > -1) {
            eindex = 4
          }
          if (value.saveMote.indexOf('p810524') > -1) {
            eindex = 5
          }
          predefined_face_id = this.defaulttx[eindex]
        }
        var eindex = 0
        if (value.saveMote.indexOf('p810525') > -1) {
          eindex = 0
        }
        if (value.saveMote.indexOf('p810527') > -1) {
          eindex = 1
        }
        if (value.saveMote.indexOf('p810528') > -1) {
          eindex = 2
        }
        if (value.saveMote.indexOf('p810526') > -1) {
          eindex = 3
        }
        if (value.saveMote.indexOf('p810522') > -1) {
          eindex = 4
        }
        if (value.saveMote.indexOf('p810524') > -1) {
          eindex = 5
        }
        model_description = this.defaulttx[eindex]
        var eindex = 0
        if (value.saveChangjing.indexOf('pengpai') > -1) {
          eindex = 0
        }
        if (value.saveChangjing.indexOf('jiepai') > -1) {
          eindex = 1
        }
        if (value.saveChangjing.indexOf('hai') > -1) {
          eindex = 2
        }
        if (value.saveChangjing.indexOf('baise') > -1) {
          eindex = 3
        }
        if (value.saveChangjing.indexOf('heise') > -1) {
          eindex = 4
        }
        if (value.saveChangjing.indexOf('huise') > -1) {
          eindex = 5
        }
        if (value.saveChangjing.indexOf('oushi') > -1) {
          eindex = 6
        }
        if (value.saveChangjing.indexOf('rishi') > -1) {
          eindex = 7
        }
        if (value.saveChangjing.indexOf('zhongshi') > -1) {
          eindex = 8
        }
        if (value.saveChangjing.indexOf('senlin') > -1) {
          eindex = 9
        }
        if (value.saveChangjing.indexOf('gaoshan') > -1) {
          eindex = 10
        }
        if (value.saveChangjing.indexOf('yinghua') > -1) {
          eindex = 11
        }
        if (value.saveChangjing.indexOf('gongyuan') > -1) {
          eindex = 12
        }
        if (value.saveChangjing.indexOf('xiuchang') > -1) {
          eindex = 13
        }
        if (value.saveChangjing.indexOf('nongchang') > -1) {
          eindex = 14
        }
        if (value.saveChangjing.indexOf('cao') > -1) {
          eindex = 15
        }
        if (value.saveChangjing.indexOf('juhui') > -1) {
          eindex = 16
        }
        if (value.saveChangjing.indexOf('yongchi') > -1) {
          eindex = 17
        }
        if (value.saveChangjing.indexOf('jianshen') > -1) {
          eindex = 18
        }
        if (value.saveChangjing.indexOf('tushu') > -1) {
          eindex = 19
        }
        if (value.saveChangjing.indexOf('taikong') > -1) {
          eindex = 20
        }
        if (value.saveChangjing.indexOf('matou') > -1) {
          eindex = 21
        }
        if (value.saveChangjing.indexOf('jujia') > -1) {
          eindex = 22
        }
        if (value.saveChangjing.indexOf('jiao') > -1) {
          eindex = 23
        }
        if (value.saveChangjing.indexOf('youle') > -1) {
          eindex = 24
        }
        if (value.saveChangjing.indexOf('duocai') > -1) {
          eindex = 25
        }
        if (value.saveChangjing.indexOf('xuejing') > -1) {
          eindex = 26
        }
        if (value.saveChangjing.indexOf('ditie') > -1) {
          eindex = 27
        }
        if (value.saveChangjing.indexOf('cheku') > -1) {
          eindex = 28
        }
        if (value.saveChangjing.indexOf('louti') > -1) {
          eindex = 29
        }
        if (value.saveChangjing.indexOf('tuiya') > -1) {
          eindex = 30
        }
        if (value.saveChangjing.indexOf('jietou') > -1) {
          eindex = 31
        }
        if (value.saveChangjing.indexOf('zhantai') > -1) {
          eindex = 32
        }
        if (value.saveChangjing.indexOf('oushiwoshi') > -1) {
          eindex = 33
        }
        if (value.saveChangjing.indexOf('inswoshi') > -1) {
          eindex = 34
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        background_description = this.changjinglist[eindex]
        const response3 = await apiClient.get('/upload-image', {

          params: {
            imageUrl: value.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url
        var myindex = 0
        if (value.saveFaxing.indexOf('faxing1') > -1) {
          myindex = 0
        }
        if (value.saveFaxing.indexOf('faxing2') > -1) {
          myindex = 1
        }
        if (value.saveFaxing.indexOf('faxing3') > -1) {
          myindex = 2
        }
        if (value.saveFaxing.indexOf('faxing4') > -1) {
          myindex = 3
        }
        if (value.saveFaxing.indexOf('faxing5') > -1) {
          myindex = 4
        }
        if (value.saveFaxing.indexOf('faxing6') > -1) {
          myindex = 5
        }
        if (value.saveFaxing.indexOf('faxing7') > -1) {
          myindex = 6
        }
        if (value.saveFaxing.indexOf('faxing8') > -1) {
          myindex = 7
        }
        if (value.saveFaxing.indexOf('faxing9') > -1) {
          myindex = 8
        }
        if (value.saveFaxing.indexOf('faxing10') > -1) {
          myindex = 9
        }
        if (value.saveFaxing.indexOf('faxing11') > -1) {
          myindex = 10
        }
        if (value.saveFaxing.indexOf('faxing12') > -1) {
          myindex = 11
        }
        if (value.saveFaxing.indexOf('faxing13') > -1) {
          myindex = 12
        }
        if (value.saveFaxing.indexOf('faxing14') > -1) {
          myindex = 13
        }
        if (value.saveFaxing.indexOf('faxing15') > -1) {
          myindex = 14
        }

        var hairstyles = this.hairstyles[myindex]
        if (value.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (value.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (value.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (value.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (value.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (value.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        if (value.saveBiaoqing.indexOf('biaoqing7') > -1) {
          eindex = 6
        }
        var biaoqing = this.biaoqinglist[eindex];

        const response = await apiClient.get('/upload-image', {
          params: {
            imageUrl: value.originalUrl
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        let base_image = response.data.url;
        const params = {
          base_image_url: base_image,
          mask_image_url: value.cutdownUrl,
          predefined_face_id: predefined_face_id,
          background_image_url: background_image_url,
          face_image_url: face_image_url,
          model_description: model_description,
          background_description: background_description,
          mycount: value.num,
          aspect_ratio: value.radio === that.$t('yuanshi') ? '1:1' : value.radio,
          biaoqing: biaoqing,
          hairstyle_description: hairstyles,
        };
        const res = await apiClient.get(`/getrentaitu1`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        var taskid = res.data.output.task_id
        this.zhenrenCount = 0;
        that.historyBack.client_id = taskid;
        value.client_id = taskid;
        value.img1 = value.originalUrl;
        value.models = value.saveMote;
        value.img1k = value.cutdownUrl;
        value.faxing = value.saveFaxing;
        value.biaoqing = value.saveBiaoqing;
        value.changjing = value.saveChangjing;
        value.module=this.currentMenu;
        value.bili = value.radio;
        that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },5000)
        var t1 = setInterval(async function () {
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.paidui=false;
            that.$message.error("真人图失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpatht = window._CONFIG['domianURL'] + path2.replace('./output/', '')

            var vpathr ='';
            try {
              vpathr =await that.resultXiulian(vpatht);
            }catch (e) {
              try {
                vpathr =await that.resultXiulian(vpatht,'107');
              }catch (e) {
                that.$message.error('生成失败')
                that.paidui=false;
                that.delHistoryNoPermission({id:that.max_id});
                that.isLoadingXiaoguo = false;
                return false;
              }
            }


            var vpath=await that.xianshangImg(vpathr,'2');
            value.imgJieguo = vpath;
            if (value.module==that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = vpath
              value.imgJieguo = that.vpath1;
              that.historyBack = value;
            }
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,
              }
            });
            that.koujian('2',value)
            if (value.module==that.currentMenu){
              that.isLoadingXiaoguo = false;
            }

            if(!that.vpath1&&value.id==that.select_id){
              that.vpath1 = vpath
              that.historyBack = value;
              const showimg = that.historyAll.find(it => it.id === value.id);
              that.jieguoTime = showimg.create_time;
            }
          }
        }, 8000)


      }
      if (this.currentMenu === '4') {
        // 商品图
        // 防止过快点击过次生成
        this.shangpinCount += 1;
        if (this.shangpinCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 2) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const response3 = await apiClient.get('/upload-image', {
          params: {
            imageUrl: value.saveChangjing,
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var background_image_url = response3.data.url
        const response = await apiClient.get('/generate-background', {
          params: {
            baseImageUrl: value.cutdownUrl,
            refImageUrl: background_image_url,
            refPrompt: value.prompts,
            n: 1,
            noiseLevel: 350,
            refPromptWeight: 0.3,
            sceneType: 'COSMETIC'
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var backgrounds = response.data;
        var t1 = setInterval(async function () {
          var taskid = response.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.$message.error("商品图生成失败~")
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
            if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = vpath
            }
            that.shangpinCount = 0;
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: that.vpath1,
                username: localStorage.username,
              }
            });
            that.koujian('5')
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      }
      if (this.currentMenu === '6') {
        // 防止过快点击过次生成
        this.xieziCount += 1;
        if (this.xieziCount !== 1) {
          this.$message.warning('任务正在生成中，请稍后');
          return;
        }
        var tresponse1 = await apiClient.get('/getcishu', {
          params: {
            username: localStorage.username,
          }
        });
        var train = tresponse1.data.data[0].userCoins
        train = parseInt(train)
        if (train < 2) {
          this.$message.error(this.$t('taocanyiyongwan'))
          this.showModal = true
          return false
        }
        var that = this;
        var face_image_url = ''
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const response = await apiClient.get('/upload-image', {
          params: {
            imageUrl: value.moteurl
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        face_image_url = response.data.url
        this.isLoadingXiaoguo = false;
        this.isLoadingXiaoguo = true;
        this.loadingText = this.$t('aituxingloading');
        const params = {
          template_image_url: face_image_url,
          shoe_image_url: value.cutdownUrl
        };
        const res = await apiClient.get(`/submitJob`, {
          params: params,
          headers: {
            'x-access-token': localStorage.token
          },
        });
        var t1 = setInterval(async function () {
          var taskid = res.data.output.task_id
          var tresponse1 = await apiClient.get('/gettask1', {
            params: {
              taskId: taskid,
            }
          });
          var status12 = tresponse1.data.status12.task_status
          if (status12 == 'FAILED') {
            clearInterval(t1)
            that.isLoadingXiaoguo = false;
            that.xieziCount = 0;
            that.$message.error(that.$t('huanxieshibai'))
          }
          if (status12 == 'SUCCEEDED') {
            clearInterval(t1)
            var imgurl1 = tresponse1.data.status12.results[0].url
            var response2 = await apiClient.get('/downloadmyurl', {
              params: {
                url2: imgurl1,
              }
            })
            var path2 = response2.data.outputPath
            var vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '')
            if (!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
              that.vpath1 = vpath
            }
            that.xieziCount = 0;
            var tresponse1 = await apiClient.get('/inserrlist', {
              params: {
                resulturl: vpath,
                username: localStorage.username,
              }
            });
            that.koujian('6')
            that.isLoadingXiaoguo = false;
          }
        }, 8000)
      }
    },
    async gotohuanzhuang() {
      this.$message.warning("选择该训练结果，用作换装功能～")
      this.currentMenu = '1'
      this.moteurl = this.vpath1
      this.historyBack.models2 = this.vpath1;
      this.vpath1 = '';
      const historyList = await this.getHistoryList('1');
      if(this.currentMenu=='1'){
        this.historyList = historyList;
      }
    },
    // 所有模块加完历史任务后删除此方法
    clearAllCount() {
      this.shangpinCount = 0;
      this.xieziCount = 0;
      this.moteCount = 0;
      this.gaikuanCount = 0;
      this.wenshengCount = 0;
      this.huaxingCount = 0;
      this.xiangaoCount = 0;
    },
    selectMenu1(){
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        this.$refs.cdmenu.activeIndex = this.currentMenu;
        return  false;
      }
    },
    async selectMenu(index) {

      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        this.$refs.cdmenu.activeIndex = this.currentMenu;
        return  false;
      }

      this.jieguoTime='';
      this.saveHistoryIndex1 = null;
      if (this.isLoadingXiaoguo) {
        this.isGenerateChangeMenu = true;
        this.clearAllCount();
      }
      this.vpath1 = ''
      this.isLoadingXiaoguo = false
      this.moteurl = window._CONFIG['domianURL']+'moren/weishangchuan.png'
      // alert(index)

      this.currentMenu = index;
      const historyList = await this.getHistoryList(index);
      if(this.currentMenu==index){
        this.historyList = historyList;
      }
      this.dingshi();
      this.saveHistoryIndex = null;
    },
    checkAllResourcesLoaded() {
      // 在这里可以添加更多判断条件
      // 如图片加载、异步数据请求等
      this.allResourcesLoaded = true;
      this.myloading.close()
    },
    zoomImage() {
      if (Number(this.currentMenu) <= 3) {
        this.imgPreviewVisible = true;
      } else {
        this.imgPreviewVisible2 = true;
      }
      this.scalePercent = 100;
      setTimeout(() => {
        const panzoomElement = this.$refs.panzoomElement;
        // 初始化 Panzoom 实例
        this.panzoomInstance = Panzoom(panzoomElement, {
          maxScale: 3,
          minScale: 0.5,
        });
        // panzoomElement.addEventListener('wheel', this.panzoomInstance.zoomWithWheel);
      }, 1000)
    },
    async downloadImage() {
      const response = await axios.get(this.vpath1, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.png'); // Specify the file name
      document.body.appendChild(link);
      link.click();
    },
    async koujian(mystyle,value) {
      const response = await apiClient.get('/insertxiaofei', {
        params: {
          userId: localStorage.username,
          type: 'image_change',
          details: 'AI换装',
          mystyle: mystyle
        },
        headers: {
          'x-access-token': localStorage.token
        },

      });

      console.log("本次的historyBack");
      console.log(value);
      if(value){
        this.updateHistory(value);
      }else{
        this.updateHistory(this.historyBack);
      }


    },
    async insertHistory(history) {
      const banxing = history.unitType ? history.unitType : history.banxing;
      if(this.currentMenu=='1'){
        // 向历史中插入
        const obj = {
          module: history.module, //菜单
          img1: history.img1, //原图1
          img2: history.img2, //原图2
          img3: history.img3, //原图3
          type: history.type, //单件/多件
          models: history.moteurl, //使用的模特
          posture: history.posture, //模特库模特/姿势
          models2: history.models2, //自定义上传模特
          banxing: banxing || '', //上装/下装/连体衣
          img1k: history.img1k, //保留区1
          img2k: history.img2k, //保留区2
          img3k: history.img3k, //保留区3
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'1':history.fuwuqi,
          is_running:'1',
          money:'10',
          ip:history.ip,
          client_id:history.client_id,
          user:localStorage.username,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      if(this.currentMenu=='2'||this.currentMenu=='3'){
        const obj = {
          module: history.module,
          img1: history.img1,
          models: history.models,
          img1k: history.img1k,
          faxing: history.faxing,
          biaoqing: history.biaoqing,
          changjing: history.changjing,
          bili: history.bili,
          imgJieguo:'',
          fuwuqi:!history.fuwuqi?'gettask1':history.fuwuqi,
          is_running:'1',
          client_id:history.client_id,
          money:'4',
          ip:history.ip,
          user:localStorage.username,
        };
        const response1 = await apiClient.post('/insertHistory', obj);
        console.log(response1);
        if (response1.status === 200) {
          this.max_id=response1.data.lastID;
          const historyList = await this.getHistoryList(this.currentMenu);
          this.historyList = historyList;
          this.dingshi();
        }
      }
      this.paidui=false;
    },
    async updateHistory(history) {
      if(this.currentMenu=='1'||this.currentMenu=='2'||this.currentMenu=='3'){
        const response = await apiClient.get('/updateHistory', {
          params: {
            imgJieguo: history.imgJieguo,
            client_id: history.client_id
          },
        });
        if (response.status === 200) {
          const historyList = await this.getHistoryList(this.currentMenu);
          // if (!this.isGenerateChangeMenu && !this.isGenerateChangeTask) {
          //   this.handleHistory({ id: historyList[0].id })
          // }
          if (history.module==this.currentMenu&&!this.isGenerateChangeMenu && !this.isGenerateChangeTask) {
            this.handleHistory({ id: historyList[0].id })
          }
          this.historyList = historyList;
        }
      }
    },
    async huianyi1(value,fuwuqi) {
      try {
      // 防止过快点击过次生成
      this.huanyi1Count += 1;
      this.paidui=true;
      if (this.huanyi1Count !== 1) {
        // this.$message.error('任务正在生成中，请稍后');
        this.$message.warning('排队中~请等待2秒再继续操作');
        return;
      }
      this.vpath1 = ''
      this.saveHistoryIndex1 = null;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.handleEmpty();
      var that = this
      if (value.img1k.length == 0) {
        this.$message.error(this.$t('shangchuanliantiyi'))
        return false
      }

      if (value.moteurl.length == 0) {
        this.$message.error(this.$t('qingxuanzemote'))
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {

          username: localStorage.username,

        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 10) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      this.vpath = ''
      this.vpath1 = ''
      //AI全身换装
      var headers = {
        'x-access-token': localStorage.token
      }
      const base64Image1 = await this.convertImageToBase64(value.moteurl);
      var requestData = {
        "imageUrl": base64Image1,
        "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
      }
      const responset = await apiClient.post('/comupload', requestData, { headers });
      var qpath = responset.data.name
      const base64Image2 = await this.convertImageToBase64(value.img1k);
      var requestData1 = {
        "imageUrl": base64Image2,
        "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
      }
      const responset1 = await apiClient.post('/comupload', requestData1, { headers });
      var qpath1 = responset1.data.name
        let mystyle='overall';
      if(value.unitType=='上装'){
        mystyle='upper';
      }
        if(value.unitType=='下装'){
          mystyle='lower';
        }
      var requestData2 = { "mystyle": mystyle, "img1": qpath, "img2": qpath1,"fuwuqi":fuwuqi=='107'?'baseUrl107_8188':'' }
      const responset2 = await apiClient.post('/comprompt', requestData2, { headers });
      var prompt_id = responset2.data.prompt_id
      this.huanyi1Count = 0;
      value.client_id=prompt_id;
      value.ip=fuwuqi=='107'?'baseUrl107_8188':'';
      value.module=this.currentMenu;
      that.insertHistory(value);
      setTimeout(()=>{
        value.id=that.max_id;
      },5000)

      var t1 = setInterval(async function () {
        try {
        var tresponse1 = await apiClient.get('/comfytask', {
          params: {
            prompt_id: prompt_id,
            "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''

          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var data = tresponse1.data

          if(JSON.stringify(data) != '{}'){
            const specificId = prompt_id;
            const status1 = data[specificId]?.status

            if (status1&&status1.completed == true) {
              clearInterval(t1)
              const outputsForId = data[specificId]?.outputs;
              const imagesForEight = outputsForId?.["18"]?.images;
              const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
              var response2 = await apiClient.get('/downloadmyurl', {
                params: {
                  url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&subfolder=&type=temp`:window._CONFIG['baseUrl23_8188']+`view?filename=${filename}&subfolder=&type=temp`,

                }
              })
              var path2 = response2.data.outputPath
              var vpatht = window._CONFIG['domianURL'] + path2.replace('./output/', '')

              var vpathr =await that.resultXiulian(vpatht,fuwuqi);

              var vpath=await that.xianshangImg(vpathr,'1');
              value.imgJieguo=vpath;
              if (value.module==that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
                that.vpath1 = vpath
                that.historyBack = value;
              }
              var tresponse1 = await apiClient.get('/inserrlist', {
                params: {
                  resulturl: that.vpath1,
                  username: localStorage.username,
                }
              });

              that.koujian('1',value)
              if (value.module===that.currentMenu) {
              that.isLoadingXiaoguo = false;
              }

              if(!that.vpath1&&value.id==that.select_id){
                that.vpath1 = vpath
                that.historyBack = value;
                const showimg = that.historyAll.find(it => it.id === value.id);
                that.jieguoTime = showimg.create_time;
              }

            }
          }

        }
        catch (error) {
          if(fuwuqi!='107'){
            that.huanyi1Count = 0;
            that.delHistoryNoPermission({id:that.max_id});
            clearInterval(t1);
            that.huianyi1(value,'107');
            return false;
          }{
            console.log(error);
            that.$message.error("生成失败~");
            that.vpath1='';
            that.paidui=false;
            that.isLoadingXiaoguo = false;
            that.delHistoryNoPermission({id:that.max_id});
            clearInterval(t1)
            return false;
          }

        }
      }, 2000)

      }catch (e) {
        if(fuwuqi!='107'){
          this.huanyi1Count = 0;
          this.huianyi1(value,'107');
          return false;
        }else{
          this.$message.error("生成失败~");
          this.vpath1='';
          this.paidui=false;
          this.isLoadingXiaoguo = false;
        }

      }
    },
    async huianyi(value,fuwuqi) {
      try {
        this.paidui=true;
      // 防止过快点击过次生成
      this.huanyiCount += 1;
      if (this.huanyiCount !== 1) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      var that = this
      this.vpath1 = ''
      this.saveHistoryIndex1 = null;
      this.isGenerateChangeMenu = false;
      this.isGenerateChangeTask = false;
      this.handleEmpty();
      if (value.img2k.length == 0) {
        this.$message.error(this.$t('shangchuanshangbanshen'))
        return false
      }
      if (value.img3k.length == 0) {
        this.$message.error(this.$t('shangchuanxiabanshen'))
        return false
      }
      if (value.moteurl.length == 0 || value.moteurl == null) {
        this.$message.error(this.$t('qingxuanzemote'))
        return false
      }
      var tresponse1 = await apiClient.get('/getcishu', {
        params: {
          username: localStorage.username,
        }
      });
      var train = tresponse1.data.data[0].userCoins
      train = parseInt(train)
      if (train < 10) {
        this.$message.error(this.$t('taocanyiyongwan'))
        this.showModal = true
        return false
      }
      this.isLoadingXiaoguo = true;
      this.loadingText = this.$t('aituxingloading');
      this.vpath = ''
      this.vpath1 = ''
      //AI全身换装
      var headers = {
        'x-access-token': localStorage.token
      }
      const base64Image1 = await this.convertImageToBase64(value.moteurl);
      var requestData = {
        "imageUrl": base64Image1,
        "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
      }
      const responset = await apiClient.post('/comupload', requestData, { headers });
      var qpath = responset.data.name

      const base64Image2 = await this.convertImageToBase64(value.img2k);
      var requestData1 = {
        "imageUrl": base64Image2,
        "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
      }
      const responset1 = await apiClient.post('/comupload', requestData1, { headers });
      var qpath1 = responset1.data.name
      var requestData2 = { "mystyle": "upper", "img1": qpath, "img2": qpath1,"fuwuqi":fuwuqi=='107'?'baseUrl107_8188':'' }
      const responset2 = await apiClient.post('/comprompt', requestData2, { headers });
      var prompt_id1 = responset2.data.prompt_id
      this.huanyiCount = 0;
      value.client_id=prompt_id1;
      value.ip=fuwuqi=='107'?'baseUrl107_8188':'';
      value.module=this.currentMenu;
      that.insertHistory(value);
        setTimeout(()=>{
          value.id=that.max_id;
        },5000)

      var requestDataduojian = {
        moteurl:qpath,
        img3: value.img3k,
        historyData: value,
        prompt_id: prompt_id1,
        "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
      }
      const responsetduojian = await apiClient.post('/compromptduojian', requestDataduojian, { headers });
      var prompt_id1 = responsetduojian.data.prompt_id
      value.client_id=prompt_id1;
      var t1 = setInterval(async function () {
        try {
        var tresponse1 = await apiClient.get('/comfytask8288', {
          params: {
            prompt_id: prompt_id1,
            "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
          },
          headers: {
            'x-access-token': localStorage.token
          }
        });
        var data = tresponse1.data
          if(JSON.stringify(data) != '{}'){
            const specificId = prompt_id1;
            const status1 = data[specificId]?.status

            if (status1&&status1.completed == true) {
              clearInterval(t1)
              const outputsForId = data[specificId]?.outputs;
              const imagesForEight = outputsForId?.["7"]?.images;
              const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;
              var response2 = await apiClient.get('/downloadmyurl', {
                params: {
                  url2:fuwuqi=='107'?window._CONFIG['baseUrl107_8188']+`view?filename=${filename}&type=temp&subfolder`:window._CONFIG['baseUrl23_8288']+`view?filename=${filename}&type=temp&subfolder`,
                }
              })
              var path2 = response2.data.outputPath
              var vpathr = window._CONFIG['domianURL'] + path2.replace('./output/', '')

              var vpath=await that.xianshangImg(vpathr,'1');
              value.imgJieguo=vpath;
              if (value.module===that.currentMenu&&!that.isGenerateChangeMenu && !that.isGenerateChangeTask) {
                that.vpath1 = vpath
                that.historyBack = value;
              }
              var tresponse1 = await apiClient.get('/inserrlist', {
                params: {
                  resulturl: that.vpath1,
                  username: localStorage.username,
                }
              });

              that.koujian('1',value)
              if (value.module===that.currentMenu) {
                that.isLoadingXiaoguo = false;
              }

              if(!that.vpath1&&value.id==that.select_id){
                that.vpath1 = vpath
                that.historyBack = value;
                const showimg = that.historyAll.find(it => it.id === value.id);
                that.jieguoTime = showimg.create_time;
              }

            }
          }

        }
        catch (error) {
          if(fuwuqi!='107'){
            that.huanyiCount = 0;
            that.delHistoryNoPermission({id:that.max_id});
            clearInterval(t1);
            that.huianyi(value,'107');
            return false;
          }{
            that.huanyiCount = 0;
            that.$message.error("生成失败~");
            that.vpath1='';
            that.paidui=false;
            that.isLoadingXiaoguo = false;
            that.delHistoryNoPermission({id:that.max_id});
            clearInterval(t1)
            return false;
          }
        }
      }, 2000)

      }catch (e) {
        if(fuwuqi!='107'){
          this.huanyiCount = 0;
          that.delHistoryNoPermission({id:that.max_id});
          this.huianyi(value,'107');
          return false;
        }else{
          this.huanyiCount = 0;
          this.$message.error("生成失败~");
          this.vpath1='';
          that.paidui=false;
          that.delHistoryNoPermission({id:that.max_id});
          this.isLoadingXiaoguo = false;
        }
      }
    },
    dingshi(){
      var that=this;
      let t1 = setInterval(async function () {
        if (that.hasDingshi) return;
        const historyList = await that.getHistoryList(that.currentMenu);
        if (that.historyCurrentPage === 1) {
          that.historyDialogList(that.currentMenu);
        }
        that.historyList = historyList;
        const hasEmptyImg = historyList.some(item => item.is_running == 1 || item.imgJieguo == '1');
        if (!hasEmptyImg) {
          clearInterval(t1)
        }
      }, 8000)
    },

    async convertImageToBase64(imageUrl) {
      try {
        // 使用 fetch 获取图片的二进制数据
        const response = await fetch(imageUrl);

        // 检查请求是否成功
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // 将二进制数据转换为 Blob
        const blob = await response.blob();

        // 使用 FileReader 将 Blob 转换为 Base64 编码
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        // 返回一个 Promise，当读取完成时解析为 Base64 数据
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = () => {
            reject(new Error('Failed to read the image as Base64'));
          };
        });
      } catch (error) {
        console.error('Error converting image to Base64:', error);
        return null;
      }
    },

    async xianshangImg(path,bili) {
      return new Promise((resolve, reject) => {
        apiClient.get('/upload-image-result', {
          params: {
            imageUrl: path,
            bili:bili
          },
          headers: {
            'x-access-token': localStorage.token
          }
        })
            .then(response => {
              var myurl = response.data.url;
              if (myurl) {
                resolve(myurl);
              } else {
                this.$message.error('系统异常，请联系管理人员~');
                reject(false);
              }
            })
            .catch(error => {
              this.$message.error('请求失败，请稍后重试~');
              reject(error);
            });
      });
    },

    //结果修脸
     resultXiulian(path,fuwuqi){
       return new Promise(async (resolve, reject) => {  // 在这里增加 async
         try {
           const base64Image = await this.convertImageToBase64(path);  // 这里已经有 await
           const imagurl1 = base64Image;
           const headers = {
             'x-access-token': localStorage.token
           };
           const requestData = {
             imageUrl: imagurl1,
             "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
           };

           const responseComupload = await apiClient.post('/comupload8288', requestData, { headers });
           const qpath = responseComupload.data.name;
           const requestData2 = { img1: qpath, "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':'' };
           const responset2 = await apiClient.post('/compromptxiulianFast', requestData2, { headers });
           const prompt_id = responset2.data.prompt_id;

           const t1 = setInterval(async function () {
             const taskid = prompt_id;
             const tresponse1 = await apiClient.get('/comfytask8288', {
               params: {
                 prompt_id: taskid,
                 "fuwuqi":fuwuqi=='107'?'baseUrl107_8188':''
               },
               headers: {
                 'x-access-token': localStorage.token
               }
             });
             const data = tresponse1.data;

             if (JSON.stringify(data) != '{}') {
               const specificId = prompt_id;
               const status1 = data[specificId]?.status;
               if (status1 && status1.completed === true) {
                 clearInterval(t1);
                 const outputsForId = data[specificId]?.outputs;
                 const imagesForEight = outputsForId?.["7"]?.images;
                 const filename = imagesForEight && imagesForEight.length > 0 ? imagesForEight[0].filename : null;

                 const response2 = await apiClient.get('/tdownloadmyurl', {
                   params: {
                     url2: fuwuqi=='107'?window._CONFIG['baseUrl107_8188'] + `view?filename=${filename}&type=temp&subfolder`:window._CONFIG['baseUrl23_8288'] + `view?filename=${filename}&type=temp&subfolder`,
                   }
                 });

                 const path2 = response2.data.outputPath;
                 const vpath = window._CONFIG['domianURL'] + path2.replace('./output/', '');
                 resolve(vpath);  // 使用 resolve 返回结果
               }
             }
           }, 2000);
         } catch (error) {
           reject(error);  // 捕获并处理异常
         }
       });
    },
  }
}
</script>

<style scoped>
::v-deep .el-menu-item:focus {
  background-color: transparent !important;
}
.uploadbtn {
  border-radius: 0 5px 5px 0;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  border: 0;
  padding: 13px 20px
}
.uploadbtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
.container {
  display: flex;
  height: calc(100vh - 62px);
  /* Full viewport height */
  background-color: white;
  /* height: calc(200vh - 500px);  */
}

.box {
  width:290px;
  height:auto;
  padding: 20px 14px 0px 20px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.zhanshiqu {
  width: calc(100vw - 496px);
  display: flex;
  justify-content: center;
  padding: 20px 20px;
}

.xiaoguodiv {
  flex: 9;
}

.lishicontain {
  position: relative;
  flex: 1;
  margin-left: 20px;
}
.showAll {
  position: absolute;
  bottom: 0;
  /* left: 15px; */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%);
}

.lishitu {
  height: calc(100vh - 140px);
  overflow-y: auto;
  scrollbar-width: thin;
}
.lishidiv {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.lishiimg {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  box-sizing: border-box;
}
.lishiimg :hover {
  cursor: pointer;
}
.lishiimg /deep/ .el-image__inner {
  width: 100%;
  height: auto;
}
.imgloading {
  display: flex;
  justify-content: center;
  align-items: center
}
.lishiimgtext {
  position: absolute;
  bottom: 1px;
  width: 100px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s;
}
.lishidiv:hover .lishiimgtext {
  opacity: 1;
}
.lishiimgtext :hover {
  cursor: pointer;
}
.lishiselected {
  border: 2px solid #2352d8 !important;
  padding: 1px;
}
.xiaoguotu {
  position: relative;
  box-sizing: border-box;
  border: 2px dashed rgba(4, 17, 51, 0.26);
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 140px);
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(4, 17, 51, 0.45);
  padding: 10px 0;
}

.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}

.el-button.is-plain:hover {
  color: #2352d8;
}

.el-button.is-plain:focus {
  border: 1px solid #2352d8;
  color: #2352d8;
}

.menuicon {
  width: 18px;
  height: auto;
  margin: 0 10px 0 5px;
}

.image_container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  /* 蒙层的半透明背景 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  /* 初始状态透明 */
  transition: opacity 0.3s ease;
  border-radius: 10px
}
.zoomindiv {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.botton_icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.botton_icon img {
  cursor: pointer;
}

.image_container:hover .overlay {
  opacity: 1;
  /* 鼠标悬停时显示蒙层 */
}
.previewdialog /deep/ .el-dialog__header {
  text-align: left;
  padding: 20px;
}
.previewdialog /deep/ .el-dialog {
  margin-top: 5vh !important;
}

.previewdialog /deep/ .el-dialog__body {
  height: 70vh !important;
  background: #EFF1F6;
  padding: 0;
}
.previewdialog /deep/ .el-dialog__footer {
  padding: 20px;
}

.previewdialog /deep/ .dialog-footer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yulanmote {
  flex: 2;
  background: #fff;
  margin: 20px 5px 20px 20px;
  padding: 10px;
}
.yulanmote /deep/ img {
  width: 100%;
  height: auto;
}
.yulanyuantu {
  flex: 4;
  background: #fff;
  margin: 20px 5px 20px 0;
  padding: 10px;
}
.danjian1 {
  height: calc(100% - 40px);
}
.danjian1 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.yulanduojian {
  height: calc(100% - 40px);
}
.duojian1, .duojian2 {
  height: 50%;
}
.duojian1 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.duojian2 /deep/ img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.yulanshengtu {
  position: relative;
  flex: 6;
  background: #fff;
  margin: 20px 20px 20px 0;
}
.tagdiv {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}
.previewdiv {
  position: relative;
  height: 100%;
  width: 100%;
}

.previewdiv img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.dialog_loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
}

.loading_overlay i {
  font-size: 50px;
}

.sizeicon {
  width: 160px;
  height: 35px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  left: 75%;
}
.yulanIconDiv {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  margin: 0 1px;
  padding: 9px 15px;
}
.historydialog /deep/ .el-dialog {
  margin-top: 5vh !important;
}
.historydialog /deep/ .el-dialog__body {
  background: #EFF1F6;
  height: 70vh;
  overflow: auto;
}
.lishidialogimg {
  padding: 10px 20px;
}
.lishidialogimg /deep/ img {
  border-radius: 5px;
}
.dialogcol {
  position: relative;
  width: 20%;
  padding: 5px
}
.carddiv {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #fff;
}
.dialogdel {
  position: absolute;
  bottom: 7px;
  width: calc(100% - 10px);
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.dialogcol:hover .dialogdel {
  opacity: 1;
}
.smalltag {
  text-align: left;
  margin-bottom: 10px
}
.selecticon {
  background: #fff;
}
</style>
