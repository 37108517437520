
import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles.css';
import VueMeta from 'vue-meta';
import socketPlugin from '@/services/socketPlugin';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import zh from './locales/zh.json';
Vue.use(VueMeta);
Vue.use(ElementUI);
Vue.use(socketPlugin);

import VueLazyload from 'vue-lazyload';

Vue.config.productionTip = false;
export const eventBus = new Vue();

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/error.png'),   // 引用 assets 中的错误图片
  loading: require('@/assets/loading11.gif'), // 引用 assets 中的加载图片
  attempt: 1
});

Vue.use(VueI18n);
const messages = {
  en,
  zh,
};
const i18n = new VueI18n({
  locale: localStorage.lang || 'zh', // 设置默认语言
  messages,
});

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app');
    