<template>
  <div class="gaikuan">
    <!-- <span style="font-size: 14px;">{{ $t('kuanshitu') }}</span> -->
    <CustomerUploadShow :text="$t('kuanshitu')" @uploadChange="uploadChange" />
    <div class="xiangsidiv">
      <div style="text-align: left">{{ $t('xiangsidu') }}</div>
      <el-slider class="xiangsislider" v-model="tvalue" :min='0' :max="1" :step="0.1" show-input input-size="mini" :show-tooltip="false"></el-slider>
    </div>

    <div class="tishicidiv">
      <div style="text-align: left; margin-bottom: 10px">{{ $t('tishici') }}</div>
      <el-input v-model="prompts" type="textarea" :rows="5" :placeholder="$t('qingshurutishici')"></el-input>
    </div>

    <div class="miaoliaodiv">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('xuanzebiaoqian') }}</span>
        </div>
        <el-popover placement="top-start" :title="$t('yansexuanze')" width="500" trigger="hover" content="">
          <el-button plain style="margin: 0 0 5px 5px" @click="yanse(index)" v-for="(item, index) in colorlist" :key="index">
            {{ item }}
          </el-button>
          <div class="text item" style="height:40px;line-height:40px;" slot="reference">
            {{ $t('yanse') }} <span style="color: red;font-size: 12px;">{{ mycolor }}</span>
          </div>
        </el-popover>
        <el-popover placement="top-start" :title="$t('danpinpinlei')" width="800" trigger="hover" content="">
          <div style="height: 60vh; overflow-y: auto; scrollbar-width: thin;">
            <div v-for="(kuaixing, index) in kuanxinglist" :key="index">
              <div style="margin: 10px 0 10px 0">{{kuaixing[0]}}</div>
              <el-button plain @click="danpin(index, i + 1)" v-for="(item, i) in kuaixing.slice(1, kuaixing.length)" :key="i" style="margin: 0 0 5px 5px">
                {{ item }}
              </el-button>
            </div>
          </div>
          <div class="text item" style="height:40px;line-height:40px;" slot="reference">
            {{ $t('danpinpinlei') }} <span style="color: red;font-size: 12px;">{{ mydanpin }}</span>
          </div>
        </el-popover>
        <el-popover placement="top-start" :title="$t('xingbie')" width="400" trigger="hover" content="">
          <el-button plain style="margin: 0 0 5px 5px" @click="sex(index)" v-for="(item, index) in sexlist" :key="index">
            {{ item }}
          </el-button>
          <div class="text item" style="height:40px;line-height:40px;" slot="reference">
            {{ $t('xingbie') }} <span style="color: red;font-size: 12px;">{{ mysex }}</span>
          </div>
        </el-popover>
        <el-popover placement="top-start" :title="$t('mianfuliao')" width="800" trigger="hover" content="">
          <el-button plain style="margin: 0 0 5px 5px" @click="mianliao(index)" v-for="(item, index) in mianliaolist" :key="index">
            {{ item }}
          </el-button>
          <div class="text item" style="height:40px;line-height:40px;" slot="reference">
            {{ $t('mianfuliao') }} <span style="color: red;font-size: 12px;">{{ mymianliao }}</span>
          </div>
        </el-popover>
      </el-card>
    </div>
    <!--  比例 -->
    <div style="text-align: left; margin-bottom: 10px">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('bili') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <el-radio-group v-model="radio" size="small">
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === $t('yuanshi') }"
                @click="selectRadio($t('yuanshi'))">{{ $t('yuanshi') }}</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                @click="selectRadio('1:1')">1:1</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                @click="selectRadio('3:4')">3:4</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                @click="selectRadio('16:9')">16:9</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                @click="selectRadio('9:16')">9:16</el-button>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>
    </div>

    <div class="generatenum">
      <el-input v-model="num" type="number" :min="1" :max="4" disabled>
        <template slot="prepend">{{ $t('shengchengshuliang') }}</template>
      </el-input>
    </div>

    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:4</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate3">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import CustomerUploadShow from './CustomerUploadShow.vue';

export default {
  props: ['colorlist', 'kuanxinglist', 'sexlist', 'mianliaolist'],
  components: { CustomerUploadShow },
  data() {
    return {
      original: '',
      tvalue: 0,
      prompts: '',
      mycolor: '',
      mymianliao: '',
      mysex: '',
      mydanpin: '',
      radio: this.$t('yuanshi'),
      num: 1,
    }
  },
  created() {},
  mounted() {},
  methods: {
    AIGenerate3() {
      if (this.original == '') {
        this.$message.error(this.$t('qingshangchuangaikuan'))
        return false
      }
      if (this.prompts == '') {
        this.$message.error(this.$t('qingshurutishici2'))
        return false
      }
      if (this.tvalue == 0) {
        this.$message.error(this.$t('gaikuanxiangsidu'))
        return false
      }
      if (this.mycolor == '') {
        this.$message.error(this.$t('qingxuanzeyanse'))
        return false
      }
      if (this.mydanpin == '') {
        this.$message.error(this.$t('qingxuanzeyifupinlei'))
        return false
      }
      if (this.mysex == '') {
        this.$message.error(this.$t('qingxuanzexingbie'))
        return false
      }
      if (this.mymianliao == '') {
        this.$message.error(this.$t('qingxuanzemianliao'))
        return false
      }
      const obj = {
        prompts: this.prompts,
        mycolor: this.mycolor,
        mydanpin: this.mydanpin,
        mysex: this.mysex,
        mymianliao: this.mymianliao,
        tvalue: this.tvalue,
        original: this.original,
      }
      this.$emit('AIGenerate3', obj)
    },
    uploadChange(value) {
      this.original = value;
    },
    yanse(index) {
      this.mycolor = this.colorlist[index];
    },
    mianliao(index) {
      this.mymianliao = this.mianliaolist[index]
    },
    sex(index) {
      this.mysex = this.sexlist[index]
    },
    danpin(index, i) {
      this.mydanpin = this.kuanxinglist[index][i];
    },
    selectRadio(value) {
      this.radio = value;
    },
  },
}
</script>

<style scoped>
.gaikuan {
  text-align: center;
}
.xiangsidiv, .tishicidiv {
  font-size: 14px;
  margin-top: 20px;
}
.xiangsislider {
  padding-left: 10px;
}
.miaoliaodiv {
  margin-top: 20px;
}
.box-card {
  text-align: left;
}
.item {
  font-size: 14px;
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  height: 90px;
}
.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}
.radioSelected {
  border: 1px solid #2352d8;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>