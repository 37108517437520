<template>
    <div style="height: 1000px;">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="plan_or_name" :label="$t('goumaichanpin')">
          
        </el-table-column>
        <el-table-column prop="created_at" :label="$t('goumaishijian')"></el-table-column>
        <el-table-column prop="total_price" :label="$t('zongfukuan')"></el-table-column>
        <el-table-column prop="ai_coupons" :label="$t('goumaishuliang')"></el-table-column>
      </el-table>
    </div>
</template>
  
  <script>
  import axios from 'axios';
  import { saveAs } from 'file-saver';
  import apiClient from '@/services/api'; // 假设你有一个 apiClient 处理请求
  export default {
    data() {
      return {
        tableData: [],
      
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
        var myloading = this.$loading({
        lock: true,
        text: "正在加载中请稍等~",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
      });
   
          const response = await apiClient.get('/getmyorder', {
        params: {
          username: localStorage.username,
         
        },
        headers: {
            'x-access-token': localStorage.token
          },

      });
         
          this.tableData = response.data.data;
          myloading.close()
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },
      downloadImage(url) {
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            saveAs(blob, `outfit_${Date.now()}.png`);
          })
          .catch((error) => {
            console.error('Error downloading image:', error);
          });
      },
    },
  };
  </script>
<style scoped>
.el-table th, .el-table td {
  text-align: center;
  vertical-align: middle;
}

.el-button {
  margin: 5px;
}

.el-table-column img {
  border: 1px solid #eaeaea;
  padding: 5px;
}

.el-table {
  background-color: #f9f9f9;
}

.el-table__header {
  background-color: #f2f2f2;
}

.el-table__body {
  background-color: white;
}
</style>
  