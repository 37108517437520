<template>
  <div>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first0"></el-tab-pane>
      <el-tab-pane label="亚洲男模" name="first1"></el-tab-pane>
      <el-tab-pane label="亚洲女模" name="first2"></el-tab-pane>
      <el-tab-pane label="美洲男模" name="first3"></el-tab-pane>
      <el-tab-pane label="美洲女模" name="first4"></el-tab-pane>
      <el-tab-pane label="欧洲男模" name="first5"></el-tab-pane>
      <el-tab-pane label="欧洲女模" name="first6"></el-tab-pane>
      <el-tab-pane label="非洲男模" name="first7"></el-tab-pane>
      <el-tab-pane label="非洲女模" name="first8"></el-tab-pane>
      <el-tab-pane label="东南亚男模" name="first9"></el-tab-pane>
      <el-tab-pane label="东南亚女模" name="first10"></el-tab-pane>
    </el-tabs> -->
    <div class="motegallery">
      <div class="motecard">
        <div v-for="model in models.slice(0, 6)" :key="model.id" >
          <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
          <!-- <img v-if="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad"> -->
          <!-- <i v-else class="el-icon-loading"></i> -->
        </div>
      </div>
      <div class="motecard">
        <div v-for="model in models.slice(6, 12)" :key="model.id">
          <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
        </div>
      </div>
      <div class="motecard">
        <div v-for="model in models.slice(12, 18)" :key="model.id">
          <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
        </div>
      </div>
      <div class="motecard">
        <div v-for="model in models.slice(18, 24)" :key="model.id">
          <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
        </div>
      </div>
      <div class="motecard">
        <div v-for="model in models.slice(24, 30)" :key="model.id">
          <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
        </div>
      </div>
    </div>
    <div ref="loadMore" class="loader">
      <el-spinner v-if="loading" />
    </div>
    <!-- <div class="model-gallery">
      <div v-for="model in models" :key="model.id" class="model-card">
        <img v-lazy="model.image_path" :alt="model.name" @load="handleImageLoad" @error="handleImageLoad">
      </div>
      <div ref="loadMore" class="loader">
        <el-spinner v-if="loading" />
      </div>
      <div v-if="allImagesLoaded" class="all-loaded-message">
        所有图片已加载完成!
      </div>
    </div> -->
  </div>
</template>

<script>
import apiClient from '@/services/api';

export default {
  name: 'ModelGallery',
  data() {
    return {
      activeName: "first0",
      models: [],
      loading: false,
      page: 1,
      limit: 10,
      observer: null,
      categoryMap: {
        first0: { continent: '', gender: '' },
        first1: { continent: 'Asia', gender: 'male' },
        first2: { continent: 'Asia', gender: 'female' },
        first3: { continent: 'America', gender: 'male' },
        first4: { continent: 'America', gender: 'female' },
        first5: { continent: 'Europe', gender: 'male' },
        first6: { continent: 'Europe', gender: 'female' },
        first7: { continent: 'Africa', gender: 'male' },
        first8: { continent: 'Africa', gender: 'female' },
        first9: { continent: 'Singapore', gender: 'male' },
        first10: { continent: 'Singapore', gender: 'female' }
      },
      loadedImagesCount: 0,
      totalImagesCount: 0,
      allImagesLoaded: false
    };
  },
  mounted() {
    this.loadMoreModels();
    // this.observer = new IntersectionObserver(this.handleIntersect, {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 1.0
    // });
    // this.observer.observe(this.$refs.loadMore);

  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.page = 1; // 重置分页
      this.models = []; // 清空当前模型数据
      this.loadedImagesCount = 0; // 重置加载计数
      this.totalImagesCount = 0; // 重置总图片计数
      this.allImagesLoaded = false; // 重置所有图片加载完成状态
      this.loadMoreModels(); // 重新加载模型数据
    },
    async loadMoreModels() {
      if (this.loading) return;
      this.loading = true;
      try {

        const { continent, gender } = this.categoryMap[this.activeName];
        const response = await apiClient.get(`/imodels`, {
          params: {
            page: this.page,
            limit: this.limit,
            continent: continent,
            gender: gender
          }
        });
        const mylist = response.data.models;
        this.totalImagesCount += mylist.length;
        for (let i = 0; i < mylist.length; i++) {
          let item = mylist[i];
          let path = window._CONFIG['domianURL'];
          let contentpaht = item.image_path;
          contentpaht = contentpaht.replace("./output", "");
          let path1 = path + contentpaht;
          item.image_path = path1;
        }
        this.models.push(...mylist);
        this.page += 1;

      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    handleIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMoreModels();
      }
    },
    handleImageLoad() {
      this.loadedImagesCount++;
      if (this.loadedImagesCount === this.totalImagesCount) {
        this.allImagesLoaded = true;
      }

    }
  },
  watch: {
    models(newModels) {
      if (newModels.length === 0) {
        this.allImagesLoaded = false;
      }
    }
  }
};
</script>

<style scoped>
.motegallery {
  width: 100%;
  margin-top: 40px;
  display: flex;
  gap: 20px;
  padding: 0 50px;
  box-sizing: border-box;
}
.motecard {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.motecard img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.model-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
  scrollbar-width: none; /* For Firefox */
  z-index: 99999999;
}

.model-gallery::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.model-card {
  width: 200px;
  text-align: center;
}

.model-card img {
  width: 100%;
  border-radius: 10px;
}

.loader {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.all-loaded-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: green;
}
</style>
