
<template>
  <div id="app">
    <Header @menu-select="handleMenuSelect" />
    <component :is="currentView"  style="z-index: 9999;" v-bind="currentView === 'AIModelLibrary' && { menuIndex: modelLibraryMenu }" />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Home from './views/Home.vue';
import AIModelLibrary from '@/components/AIModelLibrary.vue';
import AboutUs from '@/components/AboutUs.vue';
import zuopin from '@/components/zuopin.vue';
import order from '@/components/order.vue';
import xiaofei from '@/components/xiaofei.vue';
export default {
  name: 'App',
  components: {
    Header,
    Home,
    AIModelLibrary,
    AboutUs,
    zuopin,
    order,
    xiaofei
  },
  data() {
    return {
      modelLibraryMenu: null,
      title: 'AI商拍平台 - Vue项目',
      meta: [
        { name: 'description', content: '这是一个AI商拍平台，提供智能换装功能。' },
        { name: 'keywords', content: 'AI换装, Vue, 前端开发, 智能换装' },
        { property: 'og:title', content: 'AI商拍平台 - Vue项目' },
        { property: 'og:description', content: '这是一个AI商拍平台，提供智能换装功能。' },
        { property: 'og:image', content: '链接到你的预览图片' },
        { property: 'og:url', content: '你的页面URL' }
      ],
      currentView: 'Home'
    };
  },
  methods: {
    handleMenuSelect(view, index) {
      this.currentView = view;
      if (view === 'AIModelLibrary') {
        this.modelLibraryMenu = index;
      }
    }
  },
};
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
}
</style>
    