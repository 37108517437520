<template>
  <div class="body" v-infinite-scroll="scrollLoad">
    <div class="toptitle">
      <div></div>
      <div class="topbtns">
        <div class="leftbtn" :class="{'selectedbtn': radio === '精选图库'}" @click="handlebtn('精选图库')">精选图库</div>
        <div class="rightbtn" :class="{'selectedbtn': radio === '定制模特'}" @click="handlebtn('定制模特')">定制模特</div>
        <!-- <el-radio-group v-model="radio">
          <el-radio-button label="精选图库"></el-radio-button>
          <el-radio-button label="定制模特"></el-radio-button>
        </el-radio-group> -->
      </div>
      <i class="el-icon-close" @click="handleClose" style="font-size: 24px; color: #000; cursor: pointer;" />
    </div>

    <div class="content" v-show="radio === '精选图库'">
      <!-- <span v-show="title.qvisible">
        {{ $t('diqu') }}：
        <el-select v-model="darea" placeholder="请选择" @change="selectArea">
          <el-option
            v-for="item in garea"
            :key="item.en"
            :label="item.show"
            :value="item.zh">
          </el-option>
        </el-select>
        {{ $t('xingbie') }}：
        <el-select v-model="dsex" placeholder="请选择" @change="selectSex">
          <el-option
            key="男"
            :label="$t('nan')"
            value="男">
          </el-option>
          <el-option
            key="女"
            :label="$t('nv')"
            value="女">
          </el-option>
        </el-select>
      </span> -->
      <div v-show="title.qvisible">
        <el-button
          plain
          v-for="(item, index) in typeTags"
          :key="index"
          class="clothesType"
          :class="{'clothesSelected' : clothesSelected === item}"
          @click="handleTags(item)"
        >
          {{item}}
        </el-button>
      </div>
      <div v-for="(list, index) in imgList" :key="index">
        <div class="imgtitle" v-show="title.name.length !== 0">{{title.name[index]}}</div>
        <template>
          <div class="image-container">
            <!-- 每行有五张图 -->
            <div v-for="(item, i) in list" :key="i" class="image-wrapper" :class="{'first': i % 5 === 0}" @click="moreImgSelect(item)">
              <el-image
                :src="typeof(item) === 'string' ? item : item.url"
                :class="{'selected': savePhoto === (typeof(item) === 'string' ? item : item.url)}"
                class="image"
                :lazy="true"
              >
                <div slot="placeholder" class="imgloading">
                  <img src="@/assets/loading11.gif" style="height: 100%; width: auto" />
                </div>
              </el-image>
              <div class="imgtext" v-show="(typeof(item) !== 'string')">{{typeof(item) === 'string' ? '' : item.name}}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="content" v-show="radio === '定制模特'" style="text-align: center">
      <div class="zhuanshu">你还没有专属模特姿势,快去定制你的专属模特姿势~</div>
      <div class="dingzhidiv">
        <img src="@/assets/qiyevx.png" class="vximg" />
      </div>
      <div class="lianxiai">联系AI专家订制企业专属模特姿势</div>
      <div class="zhouqi">订制开发周期预计10~15个工作日</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selected', 'imgList', 'title'],
  data() {
    return {
      radio: '精选图库',
      qvisible:false,
      dsex:'全部',
      dvalue:'全部',
      darea: '全部',
      garea: [
        {
          show: this.$t('yazhou'),
          zh: "亚洲",
          en: "Asia",
        },
        {
          show: this.$t('ouzhou'),
          zh: "欧洲",
          en: "Europe",
        },
        {
          show: this.$t('feizhou'),
          zh: "非洲",
          en: "Africa",
        },
        {
          show: this.$t('meizhou'),
          zh: "美洲",
          en: "America",
        },
        {
          show: this.$t('dayangzhou'),
          zh: "大洋洲",
          en: "Oceania",
        },
      ],
      savePhoto: '',
      typeTags: ['全部', '男装', '女装', '童装', '吊带', 'T恤', '背心', '卫衣', '毛衣', '衬衫', '外套', '短裤', '长裤', '半身裙','连衣裙','西装'],
      clothesSelected: '全部',
    }
  },
  created() {
    this.savePhoto = this.selected;
  },
  mounted() {

  },
  watch: {
    imgList(newValue, oldValue) {
       // 这里可以添加一些逻辑来判断imgList是否真的发生了有意义的变化
       // 例如，如果是引用类型，可以比较特定属性等
       // 如果只是简单重新渲染，可以直接调用$forceUpdate

     },
     dsex(newValue, oldValue) {
      var that=this

       setTimeout(() => {
         that.$forceUpdate();
       }, 1000);

     },
     darea(newValue, oldValue) {
      var that=this

       setTimeout(() => {
         that.$forceUpdate();
       }, 1000);

     },
    selected(value) {
      this.savePhoto = value;
    }
  },
  methods: {
    scrollLoad() {
      this.$emit('scrollLoad');
    },
    handleTags(val) {
      this.clothesSelected = val;
      this.$emit('selectClothesType', val);
    },
    handlebtn(val) {
      this.radio = val;
    },
    // selectArea(value1){
    //   this.$emit('selectArea', value1);
    // },
    // selectSex(value2){
    //   this.$emit('selectSex', value2);
    // },
    handleClose() {
      this.$emit('close');
    },
    moreImgSelect(item) {
      const value = typeof(item) === 'string' ? item : item.url;
      this.savePhoto = value;

      this.$emit('childSelected', value);
    }
  }
}
</script>

<style scoped>
.body {
  height: 500px;
  padding: 0 10px 20px 10px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.toptitle {
  position: absolute;
  text-align: center;
  height: 40px;
  width: calc(100% - 57px);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 99;
}
.topbtns {
  display: flex;
  align-items: center;
  color: #000;
  background: #efefef;
  border-radius: 5px;
  padding: 1px;
}
.leftbtn, .rightbtn {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.selectedbtn {
  font-weight: bold;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.title {
  font-size: 20px;
  color: #000;
}
.titlelabel {
  font-size: 14px;
  color: gray;
}
.content {
  color: #000;
  margin-top: 70px;
}
.imgtitle{
  font-size: 16px;
  color: #000;
  padding-bottom: 16px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
}
.image-wrapper {
  position: relative;
  width: 120px;
  height: 160px;
  margin: 0 0 20px 20px;
  cursor: pointer;
}
.first {
  margin: 0 0 18px 0;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.image /deep/.el-image__inner {
  width: auto;
  height: 100%;
}
.imgtext {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4); /* 半透明背景 */
  color: white;
  text-align: center;
  padding: 2px 0;
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  font-weight: bold;
}
.image :hover {
  cursor: pointer;
}
.imgloading {
  display: flex;
  justify-content: center;
  align-items: center
}
.selected {
  border: 2px solid #2352d8;
}
.zhuanshu {
  padding-top: 60px;
  font-size: 16px;
}
.dingzhidiv {
  height: 200px;
  width: 100%;
  padding-top: 40px;
}
.lianxiai {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.zhouqi {
  color: gray;
}
.vximg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.clothesType {
  margin: 5px 0 0 5px !important;
  padding: 10px 15px;
  border-radius: 20px;
}
.clothesSelected {
  border-color: #409EFF;
  color: #409EFF;
}
</style>
