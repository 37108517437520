<template>
    <el-dialog :visible.sync="visible" width="90%" class="custom-dialog" @close="handleClose">
        <div class="pricing-container">
            <div class="user-info">
                <el-avatar icon="el-icon-user" class="user-avatar"></el-avatar>
                <span>{{ maskedPhone }}</span>
                <span class="points">
                    &nbsp;{{ $t('shengyu') }} <strong>{{ userTexts }}</strong> {{ $t('suanlidian') }}
                   
                </span>
            </div>
            <h2 class="title">{{ $t('goumaitaocan') }}</h2>
            <div class="plans-row">
                <el-card v-for="plan in plans" :key="plan.name" shadow="hover" class="box-card"
                    :class="{ 'selected-plan': selectedPlan.name === plan.name }">
                    <div slot="header" class="clearfix header" >
                        <span>{{ plan.name }}</span>
                    </div>
                    <div class="plan-details">
                        <p class="price">{{ plan.price }} 元 <span style="color:#ccc;font-size: 13px;" v-if="plan.texts!='0'">原价 {{ plan.texts }} 元</span></p>
                        <p>{{ plan.points }} {{ $t('suanli') }}</p>
                        <p>{{ $t('yue') }} {{ plan.images }} {{ $t('zhangtupian') }}</p>
                        <p style="color: #ccc;">
                            {{ $t('keshiyonggongneng') }}
                        </p>
                        <p>{{ $t('gongnengshuoming') }}</p>

                        
                        <el-button type="primary" class="select-button" @click="selectPlan(plan)">{{ $t('lijigoumai') }}</el-button>
                    </div>
                </el-card>
                <!-- <el-card shadow="hover" class="box-card">
                    <div class="plan-details">
                        <h3>AI模特券</h3>
                        <p class="price">99 元/张</p>
                        <p>可用于购买AI模特单月使用权</p>
                        <div class="purchase-options">
                            <span>我要购买</span>
                            <el-input-number v-model="couponCount" :min="1" label="数量"></el-input-number>
                        </div>
                        <p>永久有效</p>
                        <el-button type="primary" class="select-button" @click="purchaseCoupons">购买</el-button>
                    </div>
                </el-card> -->
                <el-card shadow="hover" class="box-card">
                    <div slot="header" class="clearfix header">
                        <h3>{{ $t('motexunlian') }}</h3>
                    </div>
                    <p class="price">{{ $t('moxingbendihua') }}</p>
                    <p class="price">{{ $t('lianxiguanfang') }}</p>
                    <div class="purchase-options" style="margin-top :100px;">
                        <el-button type="primary" class="select-button" @click="openkefu1">{{ $t('lianxikefu') }}</el-button>
                    </div>
                </el-card>
            </div>
            {{ $t('buzhichituikuan') }}
        </div>
    
        <el-dialog :visible.sync="fvisible" :title="hztitle" width="500px" :modal="false"  @close="handleClose90">
            <div class="qr-code" style="text-align: center;width: 500px;height: 500px;">
            <img src="@/assets/hezuovx.png" alt="官方客服" style="width: 90%;margin-left: -35px">
          
          </div>

        </el-dialog>
       
          
       
           
    </el-dialog>
</template>

<script>
import apiClient from '@/services/api';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hztitle:'商务合作',
            userCoins:0,
            fvisible:false,
            maskedPhone: localStorage.username,
            userPoints: 100,
            userTexts: 30,
            userTrainings: 3,
            userAICoupons: 0,
            selectedPlan: {},
            selectedPlanPrice: 0,
            couponCount: 1,
            plans: [],
            checkstyle: '0'
        };
    },
    watch: {
        visible(newVal) {
            if (!newVal) {
                this.resetState();
            }
            this.initdata()
        }
    },
    mounted() {
        this.initdata()
    },
    methods: {
        handleClose90(){
            this.fvisible=false
        },
        openkefu1(){
            this.fvisible=true
        },
        async zhifu() {
            if (this.selectedPlanPrice == 0) {
                this.$message.warning("你没有选择购买的套餐~")
                return false
            }
            if (this.checkstyle == '0') {
                //购买套餐
                const response2 = await apiClient.get('/createOrder', {
                    headers: {
                        'x-access-token': localStorage.token
                    },
                    params: {
                        username: localStorage.username,
                        planId: this.selectedPlan.id,
                        aiCoupons: '1'

                    }

                });
                // alert(JSON.stringify(response2.data.orderId))
                  const response = await apiClient.get('/createOrderpay', {
                params: {
                    orderId: response2.data.orderId,
                    amount: this.selectedPlanPrice,
                    subject: `订单支付名称 ${this.selectedPlan.name}`,
                    body: `Plan ID: ${this.selectedPlan.planId}, AI Coupons:1`,
                    username:localStorage.username,
                    plan:this.selectedPlan,
                    cishu:1

                }
                });
                this.paymentUrl = response.data.url;
                window.open(this.paymentUrl)

                // const response3 = await apiClient.get('/updateorder', {
                //     headers: {
                //         'x-access-token': localStorage.token
                //     },
                //     params: {
                //         username: localStorage.username,
                //         plan: this.selectedPlan,
           

                //     }

                // });
                // this.$message.success("购买成功~")
                // this.visible=false

            }
            else if (this.checkstyle == '1') {
                const response2 = await apiClient.get('/createOrder1', {
                    headers: {
                        'x-access-token': localStorage.token
                    },
                    params: {
                        username: localStorage.username,
                        plan: this.selectedPlan,
                        aiCoupons: this.couponCount,
                        totlprice:this.selectedPlanPrice

                    }

                });
                this.selectedPlan.id='-1'
                    const response = await apiClient.get('/createOrderpay', {
                params: {
                    orderId: response2.data.orderId,
                    amount: this.selectedPlanPrice,
                    subject: `订单支付名称 AI模特训练`,
                    body: `Plan ID:  AI模特训练, AI Coupons:1`,
                    username:localStorage.username,
                    plan:this.selectedPlan,
                    cishu:this.couponCount
                }
                });
                this.paymentUrl = response.data.url;
                window.open(this.paymentUrl)
               
            }
        },
        async initdata() {
            //获取当前剩余算力图片

            var tresponse1 = await apiClient.get('/getcishu', {
                params: {

                    username: localStorage.username,

                }
            });

            this.userTexts = tresponse1.data.data[0].userCoins
            this.userAICoupons = tresponse1.data.data[0].train
            //获取所有购买方式
            var tresponse2 = await apiClient.get(`/getplans?type=${this.$i18n.locale}`, {
               
            });
            this.plans = tresponse2.data.data

        },
        selectPlan(plan) {
            this.selectedPlan = plan;
            this.selectedPlanPrice = plan.price;
            this.checkstyle = '0'
            this.zhifu()
            console.log('选择了套餐:', plan);
        },
        purchaseCoupons() {
            const total = this.couponCount * 99;
            this.selectedPlanPrice = total
            this.checkstyle = '1'
             this.zhifu()
            console.log(`购买了 ${this.couponCount} 张 AI 模特券，共计 ${total} 元`);
        },
        handleClose(done) {
            this.$emit('update:visible', false);
            done();
        },
        resetState() {
            this.selectedPlan = {};
            this.selectedPlanPrice = 0;
            this.couponCount = 1;
        }
    }
};
</script>

<style scoped>
.custom-dialog {
    top: 5vh;
}

.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
}

.user-avatar {
    margin-right: 10px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.plans-row {
    display: flex;
    justify-content: center;
    width: 100%;
}

.el-card {
    text-align: center;
    border: 1px solid #ebeef5;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #fff;
    width: 18%;
    margin: 10px;
}

.el-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.selected-plan {
    border: 2px solid #409EFF;
}

.header {
    font-size: 18px;
    font-weight: bold;
    background-color: #fafafa;
    padding: 10px 0;
}

.plan-details {
    padding: 20px;
    color: #666;
    margin-top: -30px;
}

.price {
    font-size: 20px;
    font-weight: bold;
    color: #ff6f61;
    margin: 10px 0;
}

.plan-details p {
    margin: 5px 0;
}

.select-button {
    margin-top: 15px;
    width: 100%;
    background: linear-gradient(0deg, #A835F2, #3E93F0);
    color: #fff;
}

.select-button:hover {
    background: linear-gradient(0deg, #A835F2, #3E93F0);
    color: #fff;
}

.payment-info {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ebeef5;
    background-color: #fff;
    width: 100%;
}

.qr-code {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
}

.payment-amount {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
}

.dialog-footer {
    text-align: right;
    padding: 10px 20px;
    border-top: 1px solid #ebeef5;
    background-color: #fff;
}
</style>