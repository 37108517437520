
  export default {
    install(Vue, options) {
      let socket;
      Vue.prototype.$createSocket = () => {
        if (!socket) {
          socket = new WebSocket('ws://'+window._CONFIG['ws']);
        }
        return socket;
      };
    },
  };
