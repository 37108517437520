import axios from 'axios';

// 创建一个 axios 实例，并设置基础 URL
const apiClient = axios.create({
  baseURL: window._CONFIG['domianURL'],
  headers: {
    'Content-Type': 'application/json'
  }
});

// 添加拦截器处理 403 和 500 状态码
apiClient.interceptors.response.use(
  response => response,
  error => {
    // if (error.response && (error.response.status === 403 || error.response.status === 500)) {
        if (error.response && (error.response.status === 403)) {

            // 执行退出系统操作
      localStorage.setItem('token', '');
      window.location = '/';
    }
    return Promise.reject(error);
  }
);

export default apiClient;
