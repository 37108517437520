<template>
  <div class="zhenrentu">
    <CustomerUpload
      name="真人图"
      text="上传平铺图"
      demand="要求:上传服装正面平铺图，平整无遮挡、无折叠"
      :actionUrl="urlAction"
      :username="username"
      :originalUrl="originalUrl"
      :cutdownUrl="cutdownUrl"
      :paidui="paidui"
      @fileSuccess="chengong"
    />
    <!-- 模特 -->
    <div style="text-align: left;">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('moteku') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <template v-for="(item, index) in imgList1[0]">
            <el-col :span="8" v-if="index < 2" :key="index" class="imgcol">
              <el-image :src="item" @click="handleImage('1', item)" :class="{ 'selected': saveMote === item }"
                class="image">
              </el-image>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible">
              <ImageMore v-if="imgMoreVisible" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="imgList1" :title="moreTitle" @selectClothesType="selectClothesType" @scrollLoad="scrollLoad"
              />
              <!-- <div style="text-align: center">
                <el-button type="primary" @click="loadingdata('全部')">显示更多</el-button>
              </div> -->
              <div class="more" slot="reference" @click="handleMore('1')">
                <el-image class="image" :src="imgList1[0][0]" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 发型 -->
    <div style="text-align: left;">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('faxing') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <template v-for="(item, index) in imgList3[0]">
            <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
              <el-image :key="index" :src="item" @click="handleImage('3', item)"
                :class="{ 'selected': saveFaxing === item }" class="image">
              </el-image>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible3">
              <ImageMore v-if="imgMoreVisible3" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="imgList3" :title="moreTitle" />
              <div class="more" slot="reference" @click="handleMore('3')">
                <el-image class="image" :src="imgList3[0][0]" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 表情 -->
    <div style="text-align: left;">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('biaoqing') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <template v-for="(item, index) in imgList4[0]">
            <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
              <div class="image-wrapper" @click="handleImage('4', item.url)">
                <el-image :key="index" :src="item.url" :class="{ 'selected': saveBiaoqing === item.url }"
                  class="image">
                </el-image>
                <div class="imgtext">{{ item.name }}</div>
              </div>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible4">
              <ImageMore v-if="imgMoreVisible4" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="imgList4" :title="moreTitle" />
              <div class="more" slot="reference" @click="handleMore('4')">
                <el-image class="image" :src="imgList4[0][0].url" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 场景 -->
    <div style="text-align: left;">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('changjing') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <template v-for="(item, index) in imgList5[0]">
            <el-col :span="8" v-if="index < 5" :key="index" class="imgcol">
              <div class="image-wrapper" @click="handleImage('5', item.url)">
                <el-image :key="index" :src="item.url" :class="{ 'selected': saveChangjing === item.url }"
                  class="image">
                </el-image>
                <div class="imgtext">{{ item.name }}</div>
              </div>
            </el-col>
          </template>
          <el-col :span="8" class="imgcol">
            <el-popover placement="right" width="724" trigger="manual" v-model="imgMoreVisible5">
              <ImageMore v-if="imgMoreVisible5" @close="handleCloseMore" :selected="currentSelected"
                @childSelected="childSelected" :imgList="imgList5" :title="moreTitle" />
              <div class="more" slot="reference" @click="handleMore('5')">
                <el-image class="image" :src="imgList5[0][0].url" style="filter: blur(5px);"></el-image>
                <div class="more-text">
                  <span>{{ $t('gengduo') }}</span>
                  <i class="el-icon-arrow-right" />
                </div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 比例 -->
    <div style="text-align: left; margin-bottom: 10px">
      <div style="display: flex; align-items: center; margin: 10px 0;">
        <span style="font-size: 14px;">{{ $t('bili') }}</span>
      </div>
      <div>
        <el-row :gutter="15">
          <el-radio-group v-model="radio" size="small">
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === $t('yuanshi') }"
                @click="selectRadio($t('yuanshi'))">{{ $t('yuanshi') }}</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '1:1' }"
                @click="selectRadio('1:1')">1:1</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '3:4' }"
                @click="selectRadio('3:4')">3:4</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '16:9' }"
                @click="selectRadio('16:9')">16:9</el-button>
            </el-col>
            <el-col :span="8">
              <el-button plain class="scale" :class="{ 'radioSelected': radio === '9:16' }"
                @click="selectRadio('9:16')">9:16</el-button>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>
    </div>
    <div class="generatenum">
      <el-input v-model="num" type="number" :min="1" :max="4" disabled>
        <template slot="prepend">{{ $t('shengchengshuliang') }}</template>
      </el-input>
    </div>
    <template>
      <div class="generate-footer">
        <div class="xiaohaodiv">
          <span>{{ $t('xiaohao') }}:4</span>
          <img src="@/assets/jinbi.png" class="jinbi_icon" />
        </div>
        <el-button slot="reference" class="generatebtn" type="primary" @click="AIGenerate">{{ $t('lijishengchen') }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import ImageMore from './ImageMore.vue';
import CustomerUpload from './CustomerUpload.vue';
import apiClient from '@/services/api';
import mote1 from '@/assets/mote4.jpg';
import mote2 from '@/assets/mote3.jpg';
import mote3 from '@/assets/mote5.jpg';
import mote4 from '@/assets/mote6.jpg';
import mote5 from '@/assets/mote7.jpg';
import mote6 from '@/assets/mote8.jpg';
import mote7 from '@/assets/mote9.png';

export default {
  props: ['imgList3', 'imgList4', 'imgList5', 'historyBack','paidui'],
  components: { CustomerUpload, ImageMore },
  data() {
    return {
      page: 1,
      limit: 15,
      imgList1: [
        [
          // mote1,
          // mote2,
          // mote3,
          // mote4,
          // mote5,
          // mote6,
          // mote7,
        ],
      ],
      urlAction: window._CONFIG['domianURL']+'upload1',
      username: localStorage.username,
      originalUrl: '',
      cutdownUrl: '',
      currentSelected: '', // 给子组件传值目前选择的图片
      moreTitle: {
        bigTitle: '',
        remark: '',
        name: []
      },
      imgMoreVisible: false,
      saveMote: '', // 保存模特图片
      imgMoreVisible3: false,
      saveFaxing: '', // 保存发型
      imgMoreVisible4: false,
      saveBiaoqing: '',
      imgMoreVisible5: false,
      saveChangjing: '',
      radio: this.$t('yuanshi'),
      num: 1,
    }
  },
  watch: {
    historyBack(val) {
      if (!val) return;
      this.originalUrl = val.img1;
      this.cutdownUrl = val.img1k;
      this.saveMote = val.models;
      this.saveFaxing = val.faxing;
      this.saveBiaoqing = val.biaoqing;
      this.saveChangjing = val.changjing;
      this.radio = val.bili;
    }
  },
  created() {},
  mounted() {
    this.loadingdata('全部');
  },
  methods: {
    scrollLoad() {
      this.loadingdata('全部');
    },
    async AIGenerate() {
      if (this.originalUrl == '') {
        this.$message.error(this.$t('qingshangchuanzhenrentu'));
        return false
      }
      if (this.cutdownUrl == '') {
        this.$message.error(this.$t('fushibuweikong'));
        return false
      }
      if (this.saveMote === '') {
        this.$message.error(this.$t('motetupianbuweikong'));
        return false
      }
      if (this.saveBiaoqing == '') {
        this.$message.error(this.$t('biaoqingbuweikong'));
        return false
      }
      if (this.saveChangjing == '') {
        this.$message.error(this.$t('changjingbuweikong'));
        return false
      }
      if (this.saveFaxing == '') {
        this.$message.error(this.$t('faxingbuweikong'));
        return false
      }
      const obj1 = {
        type: 'rentai',
        img1: this.originalUrl,
        models: this.saveMote,
        faxing: this.saveFaxing,
        biaoqing: this.saveBiaoqing,
        changjing: this.saveChangjing,
        bili: this.radio
      }
      const response = await apiClient.post(`/checkGenerating`, obj1);
      if (response.data.data.length !== 0) {
        this.$message.warning('任务正在生成中，请稍后');
        return;
      }
      const obj = {
        originalUrl: this.originalUrl,
        cutdownUrl: this.cutdownUrl,
        saveMote: this.saveMote,
        saveBiaoqing: this.saveBiaoqing,
        saveFaxing: this.saveFaxing,
        saveChangjing: this.saveChangjing,
        num: this.num,
        radio: this.radio
      };
      this.$emit('AIGenerate', obj);
    },
    chengong(epath, bpath) {
      this.originalUrl = epath;
      this.cutdownUrl = bpath;
    },
    selectRadio(value) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.radio = value;
    },
    handleImage(group, item) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.currentSelected = item;
      if (group === '1') {
        this.saveMote = item;
      }
      if (group === '3') {
        this.saveFaxing = item;
      }
      if (group === '4') {
        this.saveBiaoqing = item;
      }
      if (group === '5') {
        this.saveChangjing = item;
      }
    },
    handleMore(group) {
      if(this.paidui){
        this.$message.warning('排队中~请等待2秒再继续操作');
        return  false;
      }
      this.currentGroup = group;
      // 处理依次点击更多时不能关闭的BUG
      this.closeAllMore();
      if (group === '1') {
        this.imgMoreVisible = true;
        this.currentSelected = this.saveMote;
        this.moreTitle = {
          bigTitle: this.$t('suijimote'),
          remark: this.$t('suijimoteshuoming'),
          name: [''],
          qvisible: true,
        }
      }
      if (group === '3') {
        this.imgMoreVisible3 = true;
        this.currentSelected = this.saveFaxing;
        this.moreTitle = {
          bigTitle: this.$t('faxing'),
          remark: '',
          name: [''],
          qvisible: false,
        }
      }
      if (group === '4') {
        this.imgMoreVisible4 = true;
        this.currentSelected = this.saveBiaoqing;
        this.moreTitle = {
          bigTitle: this.$t('biaoqing'),
          remark: '',
          name: [],
          qvisible: false,
        }
      }
      if (group === '5') {
        this.imgMoreVisible5 = true;
        this.currentSelected = this.saveChangjing;
        this.moreTitle = {
          bigTitle: this.$t('changjing'),
          remark: '',
          name: [],
          qvisible: false,
        }
      }
    },
    closeAllMore() {
      this.imgMoreVisible = false;
      this.imgMoreVisible3 = false;
      this.imgMoreVisible4 = false;
      this.imgMoreVisible5 = false;
    },
    handleCloseMore() {
      if (this.currentGroup === '1') {
        this.imgMoreVisible = false;
      }
      if (this.currentGroup === '3') {
        this.imgMoreVisible3 = false;
      }
      if (this.currentGroup === '4') {
        this.imgMoreVisible4 = false;
      }
      if (this.currentGroup === '5') {
        this.imgMoreVisible5 = false;
      }
    },
    childSelected(value) {
      this.currentSelected = value;
      if (this.currentGroup === '1') {
        this.saveMote = value;
      }
      if (this.currentGroup === '3') {
        this.saveFaxing = value;
      }
      if (this.currentGroup === '4') {
        this.saveBiaoqing = value;
        var eindex = 0
        if (this.saveBiaoqing.indexOf('biaoqing1') > -1) {
          eindex = 0
        }
        if (this.saveBiaoqing.indexOf('biaoqing2') > -1) {
          eindex = 1
        }
        if (this.saveBiaoqing.indexOf('biaoqing3') > -1) {
          eindex = 2
        }
        if (this.saveBiaoqing.indexOf('biaoqing4') > -1) {
          eindex = 3
        }
        if (this.saveBiaoqing.indexOf('biaoqing5') > -1) {
          eindex = 4
        }
        if (this.saveBiaoqing.indexOf('biaoqing6') > -1) {
          eindex = 5
        }
        if (this.saveBiaoqing.indexOf('biaoqing7') > -1) {
          eindex = 6
        }
      }
      if (this.currentGroup === '5') {
        this.saveChangjing = value;
        var eindex = 0
        if (this.saveChangjing.indexOf('changjing1') > -1) {
          eindex = 0
        }
        if (this.saveChangjing.indexOf('changjing2') > -1) {
          eindex = 1
        }
        if (this.saveChangjing.indexOf('changjing3') > -1) {
          eindex = 2
        }
        if (this.saveChangjing.indexOf('changjing4') > -1) {
          eindex = 3
        }
        if (this.saveChangjing.indexOf('changjing5') > -1) {
          eindex = 4
        }
        if (this.saveChangjing.indexOf('changjing6') > -1) {
          eindex = 5
        }
        if (this.saveChangjing.indexOf('changjing7') > -1) {
          eindex = 6
        }
      }
    },
    selectClothesType(value) {
      this.loadingdata(value);
    },
    async loadingdata(value) {
      const response = await apiClient.get(`/getmodels55`, {
        params: {
          page: this.page,
          limit: this.limit,
          continent: '',
          gender: '',
          leixing: '青年',
          dsex: '全部',
          darea: '全部',
          fushi: value,
        },
        headers: {
          'x-access-token': localStorage.token
        },
      });
      const mylist = response.data.models;
      // this.imgList1[0] = []
      const tempArr = [];
      for (let i = 0; i < mylist.length; i++) {
        let item = mylist[i];
        let path = window._CONFIG['domianURL'];
        let contentpaht = item.image_path;
        contentpaht = contentpaht.replace("./output", "");
        let path1 = path + contentpaht;
        item.image_path = path1;
        tempArr.push(path1);
      }
      // this.$set(this.imgList1, 0, tempArr);
      this.$set(this.imgList1, 0, [...this.imgList1[0], ...tempArr]);
      this.page += 1;
    },
  }
}
</script>

<style scoped>
.imgcol {
  margin-bottom: 5px;
}
.selected {
  border: 2px solid #2352d8;
  padding: 1px;
}
.image {
  width: 87px;
  height: 87px;
  box-sizing: border-box;
  border-radius: 4px;
}
.image /deep/.el-image__inner {
  width: 100%;
  height: auto;
}
.image :hover {
  cursor: pointer;
}
.more {
  position: relative;
  width: 87px;
  height: 87px;
}
.more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
  width: 87px;
  height: 87px;
  cursor: pointer;
}
.imgtext {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  padding: 2px 0;
}
.scale {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 0;
  border: none;
}
.radioSelected {
  border: 1px solid #2352d8;
}
.generatenum {
  margin-top: 10px;
  margin-bottom: 100px;
}
.generatenum /deep/ .el-input__inner {
  text-align: center;
}
.generate-footer {
  width: 290px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 100px;
}
.xiaohaodiv {
  display: flex;
  align-items: center;
}
.jinbi_icon {
  width: 33px;
  height: auto;
  margin-left: 10px;
}
.generatebtn {
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
}
.generatebtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
</style>
